import React, { useCallback } from "react";
import { omit } from "lodash";
import { useMutation } from "@apollo/client";
import { IconInput, Icon } from "shared";
import { ParseError } from "src/lib";
import { Modal } from "client-lib";
import { IconForm } from "./icon-form";
import { UpdateIcon, UpdateIconVars, UpdateIconData } from "../queries";

interface Props {
  icon: Icon;
  onCloseRequest: () => any;
}

export function UpdateIconModal({ icon, onCloseRequest }: Props) {
  const [update, { loading, error }] = useMutation<
    UpdateIconData,
    UpdateIconVars
  >(UpdateIcon);

  const handleSubmit = useCallback(
    async (input: IconInput) => {
      await update({
        variables: {
          id: icon.id,
          patch: omit(
            input,
            "id",
            "createdAt",
            "updatedAt",
            "deletedAt",
            "__typename"
          ),
        },
      });
      onCloseRequest();
    },
    [icon, update, onCloseRequest]
  );

  return (
    <Modal onCloseRequest={onCloseRequest} title="Edit icon" width={600}>
      {error ? <ParseError error={error} /> : null}
      <IconForm
        initialValues={icon}
        disabled={loading}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}
