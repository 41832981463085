"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloatField = void 0;
var formik_field_wrapper_1 = require("./formik-field-wrapper");
var styles_1 = require("./styles");
exports.FloatField = (0, formik_field_wrapper_1.withField)(styles_1.StyledInput, {
    translateInputValue: function (rawInputValue) {
        if (rawInputValue === "") {
            return null;
        }
        // the inputs ending up with a period (like "1.") are treated by correct numbers by JS
        // but we should not transform them to numbers, because it will throw out the period
        // which makes typing period at the end impossible for users
        if (typeof rawInputValue === "string" &&
            rawInputValue[rawInputValue.length - 1] === ".") {
            return rawInputValue;
        }
        var numericValue = Number(rawInputValue);
        if (Number.isNaN(numericValue)) {
            return rawInputValue;
        }
        return numericValue;
    },
});
