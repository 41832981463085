import React, { useCallback, useMemo } from "react";
import { pick } from "lodash";
import { useMutation } from "@apollo/client";
import { OrderLineItem, OrderLineItemPatch } from "shared";
import { ParseError } from "src/lib";
import { Modal } from "client-lib";
import {
  UpdateOrderLineItemData,
  UpdateOrderLineItemVars,
  UpdateOrderLineItem,
} from "../queries";
import { OrderLineItemForm } from "./order-line-item-form";

interface Props {
  orderLineItem: OrderLineItem;
  onCloseRequest: () => void;
  onUpdated?: () => void;
}

export function UpdateOrderLineItemModal({
  orderLineItem,
  onCloseRequest,
  onUpdated,
}: Props) {
  const [update, { loading, error }] = useMutation<
    UpdateOrderLineItemData,
    UpdateOrderLineItemVars
  >(UpdateOrderLineItem);

  const initialValues = useMemo<OrderLineItemPatch>(
    () =>
      pick(
        orderLineItem,
        "periodSeconds",
        "userId",
        "clubId"
      ) as OrderLineItemPatch,
    [orderLineItem]
  );

  const handleSubmit = useCallback(
    async (patch: OrderLineItemPatch) => {
      await update({
        variables: {
          id: orderLineItem.id,
          patch,
        },
      });
      onUpdated?.();
      onCloseRequest();
    },
    [update, onUpdated, onCloseRequest, orderLineItem]
  );

  return (
    <Modal
      onCloseRequest={onCloseRequest}
      title="Edit order line item"
      width={800}
    >
      {error ? <ParseError error={error} /> : null}

      <OrderLineItemForm
        initialValues={initialValues}
        disabled={loading}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}
