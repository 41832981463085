"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registrationInputSchema = void 0;
const zod_1 = require("zod");
exports.registrationInputSchema = zod_1.z.object({
    nameFirst: zod_1.z.string(),
    nameLast: zod_1.z.string().nullable(),
    email: zod_1.z.string().email(),
    password: zod_1.z.string().min(8),
});
