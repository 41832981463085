import React from "react";
import styled from "@emotion/styled";
import {
  Button,
  TextField,
  MultilineTextField,
  gutters,
  grey30,
} from "client-lib";
import { Form, Formik, FormikProps } from "formik";
import { notificationInputFormikSchema, NotificationInput } from "./types";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

const initialValues: NotificationInput = {
  title: "",
  body: "",
  link: null,
};

interface Props {
  onSubmit: (values: NotificationInput) => any;
  disabled?: boolean;
  submitTitle?: string;
}

export function NotificationForm({ onSubmit, disabled, submitTitle }: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={notificationInputFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty }: FormikProps<NotificationInput>) => (
        <Form>
          <TextField
            label="Title"
            controlProps={{ size: 100 }}
            disabled={disabled}
            name="title"
          />
          <br />
          <MultilineTextField
            label="Body"
            controlProps={{ size: 100 }}
            disabled={disabled}
            name="body"
          />
          <br />
          <TextField
            label="Link"
            controlProps={{ size: 100 }}
            disabled={disabled}
            name="link"
          />
          <Controls>
            <Button type="submit" disabled={disabled || !isValid || !dirty}>
              {submitTitle || "Send"}
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
