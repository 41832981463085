import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch, Link } from "react-router-dom";
import { File, imgixURL } from "shared";
import { Breadcrumbs, Spinner, TBody, TR, TH, TD, Table } from "client-lib";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import { FileQuery, FileData, FileVars } from "./queries";

function Wrapper({
  children,
  file,
  fileId,
}: {
  children?: ReactNode;
  file?: File;
  fileId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Files", url: "/files" },
          { title: file ? file.originalFileName : `${fileId}` },
        ]}
      />
      <h1>
        File {file ? `\u00ab${file.originalFileName}\u00bb` : `${fileId}`}
      </h1>
      {children}
    </Layout>
  );
}

export function FilePage() {
  const { params } = useRouteMatch<{ fileId: string }>();
  const { data, loading, error } = useQuery<FileData, FileVars>(FileQuery, {
    variables: {
      fileId: params.fileId,
    },
  });

  if (loading) {
    return (
      <Wrapper fileId={params.fileId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper fileId={params.fileId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper fileId={params.fileId} />;
  }

  const file = data.File;
  return (
    <Wrapper fileId={params.fileId} file={file}>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>ID</TH>
            <TD>{file.id}</TD>
          </TR>
          <TR>
            <TH>User</TH>
            <TD>
              <Link to={`/users/${file.userId}`}>{file.userId}</Link>
            </TD>
          </TR>
          <TR>
            <TH>MIME type</TH>
            <TD>{file.mimeType}</TD>
          </TR>
          <TR>
            <TH>Original File Name</TH>
            <TD>{file.originalFileName}</TD>
          </TR>
          <TR>
            <TH>IMGIX link</TH>
            <TD>
              <a href={imgixURL(file.id)}>{imgixURL(file.id)}</a>
            </TD>
          </TR>
        </TBody>
      </Table>
      <br />
      <br />
      <img src={imgixURL(file.id, "?w=400")} />
    </Wrapper>
  );
}
