"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clubPatchSchema = exports.clubInputSchema = exports.clubSchema = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
exports.clubSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    ownerId: zod_1.z.string().uuid(),
    name: zod_1.z.string().min(1),
    abbreviation: zod_1.z.string().nullable(),
    color: zod_1.z
        .string()
        .regex(/^#([0-9a-f]){6}$/)
        .nullable(),
    imageId: zod_1.z.string().uuid().nullable(),
    description: zod_1.z.string().nullable(),
    rules: zod_1.z.string().nullable(),
    locationId: zod_1.z.string().uuid(),
    website: zod_1.z.string().nullable(),
    email: zod_1.z.string().email(),
    isPublic: zod_1.z.boolean(),
    isActive: zod_1.z.boolean(),
    deletingIn: zod_1.z.date().nullable(),
});
(0, services_1.checkSchema)(exports.clubSchema);
exports.clubInputSchema = exports.clubSchema
    .pick({
    id: true,
    name: true,
    abbreviation: true,
    color: true,
    imageId: true,
    description: true,
    rules: true,
    locationId: true,
    email: true,
    website: true,
    isPublic: true,
    isActive: true,
})
    .extend({
    categoryIds: zod_1.z.array(zod_1.z.string().uuid()).min(1),
    reachLocationIds: zod_1.z.array(zod_1.z.string().uuid()).min(1),
});
exports.clubPatchSchema = exports.clubInputSchema.omit({
    id: true,
    categoryIds: true,
    reachLocationIds: true,
    imageId: true,
});
