"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationAssetsSchema = exports.notificationSchema = exports.notificationTypeSchema = exports.NotificationType = void 0;
const zod_1 = require("zod");
const club_1 = require("./club");
const user_1 = require("./user");
// All enum values should start from NF
// because this enum is also used in ClubSetting, merged with other values
var NotificationType;
(function (NotificationType) {
    NotificationType["NF_CLUB_MEMBER_JOINED"] = "NF_CLUB_MEMBER_JOINED";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
exports.notificationTypeSchema = zod_1.z.nativeEnum(NotificationType);
exports.notificationSchema = zod_1.z.object({
    title: zod_1.z.string(),
    body: zod_1.z.string(),
    link: zod_1.z.string().nullable(),
});
exports.notificationAssetsSchema = zod_1.z.object({
    club: club_1.clubSchema.pick({ id: true, name: true }),
    user: user_1.userSchema.pick({ id: true, nameFirst: true, nameLast: true }),
});
