import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  fontSizeH3,
  HEADER_BAR_HEIGHT,
  hairlineBottom,
  gutters,
} from "client-lib";

export const PageHeader = styled.h1([
  css`
    line-height: ${HEADER_BAR_HEIGHT}px;
    font-weight: 900;
    margin: 0 ${-gutters.lg}px;
    padding: 0 ${gutters.lg}px;
    font-size: ${fontSizeH3}px;
  `,
  hairlineBottom,
]);
