import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch, Link } from "react-router-dom";
import { Location } from "shared";
import { Breadcrumbs, Spinner, TBody, TR, TH, TD, Table } from "client-lib";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import {
  LocationEnvironmentQuery,
  LocationEnvironmentData,
  LocationEnvironmentVars,
} from "./queries";
import { LocationList } from "./location-list";

function Wrapper({
  children,
  location,
  locationId,
}: {
  children?: ReactNode;
  location?: Location;
  locationId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Locations", url: "/locations" },
          { title: location ? location.name : `${locationId}` },
        ]}
      />
      <h1>
        Location {location ? `\u00ab${location.name}\u00bb` : `${locationId}`}
      </h1>
      {children}
    </Layout>
  );
}

export function LocationPage() {
  const { params } = useRouteMatch<{ locationId: string }>();
  const { data, loading, error } = useQuery<
    LocationEnvironmentData,
    LocationEnvironmentVars
  >(LocationEnvironmentQuery, {
    variables: {
      locationId: params.locationId,
    },
  });

  if (loading) {
    return (
      <Wrapper locationId={params.locationId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper locationId={params.locationId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper locationId={params.locationId} />;
  }

  const { location, parents, children } = data.LocationEnvironment;
  return (
    <Wrapper locationId={params.locationId} location={location}>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>ID</TH>
            <TD>{location.id}</TD>
          </TR>
          <TR>
            <TH>Original Zirkl id</TH>
            <TD>{location.originalZirklId}</TD>
          </TR>
          <TR>
            <TH>Name</TH>
            <TD>{location.name}</TD>
          </TR>
          <TR>
            <TH>Parents location</TH>
            <TD>
              {parents.map((parent) => (
                <div key={parent.id}>
                  <Link to={`/locations/${parent.id}`}>{parent.name}</Link>
                </div>
              ))}
            </TD>
          </TR>
        </TBody>
      </Table>
      <br />
      <h1>Children</h1>
      <LocationList locations={children} />
    </Wrapper>
  );
}
