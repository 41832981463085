import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { gutters } from "client-lib";
import { Sidebar, SIDEBAR_WIDTH } from "./sidebar";

const Container = styled.section<{ noOverflow?: boolean }>`
  overflow: hidden;
`;

const Page = styled.section`
  margin-left: ${SIDEBAR_WIDTH}px;
  padding: ${gutters.sm}px ${gutters.xl}px;
  h1:first-child {
    margin-top: ${gutters.xs}px;
    margin-bottom: ${gutters.lg}px;
  }
`;

export function Layout({
  children,
  noOverflow,
}: {
  children: ReactNode;
  noOverflow?: boolean;
}) {
  return (
    <Container noOverflow={noOverflow}>
      <Sidebar />
      <Page>{children}</Page>
    </Container>
  );
}
