import gql from "graphql-tag";
import {
  OrderEnvironment,
  Order,
  orderSchema,
  orderEnvironmentSchema,
  OrderPatch,
  OrderLineItemPatch,
  orderLineItemUncheckedSchema,
  OrderLineItem,
} from "shared";
import { getFieldsFromSchema } from "client-lib";

export const ORDER_FULL_FRAGMENT = gql`
  fragment OrderFullFragment on Order {
    ${getFieldsFromSchema(orderSchema)}
  }
`;

export const ORDER_LINE_ITEM_FULL_FRAGMENT = gql`
  fragment OrderLineItemFullFragment on OrderLineItem {
    ${getFieldsFromSchema(orderLineItemUncheckedSchema)}
  }
`;

export const Orders = gql`
  query Orders {
    Orders {
      ...OrderFullFragment
    }
  }
  ${ORDER_FULL_FRAGMENT}
`;

export type OrdersData = {
  Orders: Order[];
};

export type OrdersVars = {};

export const OrderLineItemQuery = gql`
  query OrderLineItem($id: String!) {
    OrderLineItem(id: $id) {
      ...OrderLineItemFullFragment
    }
  }
  ${ORDER_LINE_ITEM_FULL_FRAGMENT}
`;

export type OrderLineItemData = {
  OrderLineItem: OrderLineItem;
};

export type OrderLineItemVars = {
  id: string;
};

export const ORDER_ENVIRONMENT_FULL_FRAGMENT = gql`
  fragment OrderEnvironmentFullFragment on OrderEnvironment {
    ${getFieldsFromSchema(orderEnvironmentSchema)}
  }
`;

export const OrderEnvironmentQuery = gql`
  query OrderEnvironment($orderId: String!) {
    OrderEnvironment(orderId: $orderId) {
      ...OrderEnvironmentFullFragment
    }
  }
  ${ORDER_ENVIRONMENT_FULL_FRAGMENT}
`;

export type OrderEnvironmentData = {
  OrderEnvironment: OrderEnvironment;
};

export type OrderEnvironmentVars = {
  orderId: string;
};

export const UpdateOrder = gql`
  mutation UpdateOrder($patch: OrderPatch!, $id: String!) {
    UpdateOrder(patch: $patch, id: $id) {
      ...OrderFullFragment
    }
  }
  ${ORDER_FULL_FRAGMENT}
`;

export interface UpdateOrderVars {
  id: string;
  patch: OrderPatch;
}

export type UpdateOrderData = {
  Order: Order;
};

export const UpdateOrderLineItem = gql`
  mutation UpdateOrderLineItem($patch: OrderLineItemPatch!, $id: String!) {
    UpdateOrderLineItem(patch: $patch, id: $id) {
      ...OrderLineItemFullFragment
    }
  }
  ${ORDER_LINE_ITEM_FULL_FRAGMENT}
`;

export interface UpdateOrderLineItemVars {
  id: string;
  patch: OrderLineItemPatch;
}

export type UpdateOrderLineItemData = {
  Order: OrderLineItem;
};
