import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { ParseError } from "src/lib";
import { Modal } from "client-lib";
import { NotificationForm } from "./notification-form";
import {
  AdminSendNotification,
  AdminSendNotificationVars,
  AdminSendNotificationData,
} from "../queries";
import { NotificationInput } from "./types";

interface Props {
  deviceId: string;
  onCloseRequest: () => any;
  onSent?: (error: string | null) => any;
}

export function NotificationModal({ deviceId, onCloseRequest, onSent }: Props) {
  const [update, { loading, error }] = useMutation<
    AdminSendNotificationData,
    AdminSendNotificationVars
  >(AdminSendNotification);

  const handleSubmit = useCallback(
    async (input: NotificationInput) => {
      const result = await update({
        variables: {
          deviceId,
          ...input,
        },
      });
      onSent?.(result.data!.AdminSendNotification);
      onCloseRequest();
    },
    [update, onCloseRequest, deviceId, onSent]
  );

  return (
    <Modal
      onCloseRequest={onCloseRequest}
      title="Send notification"
      minWidth={500}
    >
      {error ? <ParseError error={error} /> : null}
      <NotificationForm
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle="Send"
      />
    </Modal>
  );
}
