"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.typographyCondensed = exports.labelText = exports.typographyBody3 = exports.typographyBody2 = exports.typographyBody1 = exports.typographyH3 = exports.typographyH2 = exports.typographyH1 = exports.secondaryFont = exports.primaryFont = exports.secondaryFamily = exports.primaryFamily = void 0;
var react_1 = require("@emotion/react");
var colors_1 = require("./colors");
var variables_1 = require("./variables");
exports.primaryFamily = "'Inter', sans-serif";
exports.secondaryFamily = "'Roboto', sans-serif";
exports.primaryFont = "font-family: ".concat(exports.primaryFamily, ";");
exports.secondaryFont = "font-family: ".concat(exports.secondaryFamily, ";");
exports.typographyH1 = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: ", "px;\n  font-weight: 500;\n  line-height: ", "px;\n  ", ";\n"], ["\n  font-size: ", "px;\n  font-weight: 500;\n  line-height: ", "px;\n  ", ";\n"])), variables_1.fontSizeH1, variables_1.HEADER_BAR_HEIGHT, exports.secondaryFont);
exports.typographyH2 = (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", "px;\n  font-weight: 500;\n  line-height: 32px;\n  ", ";\n"], ["\n  font-size: ", "px;\n  font-weight: 500;\n  line-height: 32px;\n  ", ";\n"])), variables_1.fontSizeH2, exports.secondaryFont);
exports.typographyH3 = (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", "px;\n  font-weight: 500;\n  line-height: 28px;\n  margin: 1em 0 0.4em;\n  ", ";\n"], ["\n  font-size: ", "px;\n  font-weight: 500;\n  line-height: 28px;\n  margin: 1em 0 0.4em;\n  ", ";\n"])), variables_1.fontSizeH3, exports.secondaryFont);
exports.typographyBody1 = (0, react_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  font-size: ", "px;\n  line-height: ", "px;\n  font-weight: 400;\n"], ["\n  ", "\n  font-size: ", "px;\n  line-height: ", "px;\n  font-weight: 400;\n"])), exports.primaryFont, variables_1.fontSizeBody1, variables_1.lineHeightBody1);
exports.typographyBody2 = (0, react_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n  font-size: ", "px;\n  line-height: ", "px;\n  font-weight: 400;\n"], ["\n  ", "\n  font-size: ", "px;\n  line-height: ", "px;\n  font-weight: 400;\n"])), exports.primaryFont, variables_1.fontSizeBody2, variables_1.lineHeightBody2);
exports.typographyBody3 = (0, react_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n  font-size: ", "px;\n  line-height: ", "px;\n  font-weight: 500;\n"], ["\n  ", "\n  font-size: ", "px;\n  line-height: ", "px;\n  font-weight: 500;\n"])), exports.primaryFont, variables_1.fontSizeBody3, variables_1.lineHeightBody3);
exports.labelText = (0, react_1.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n  font-size: ", "px;\n  line-height: 16px;\n  font-style: normal;\n  font-weight: 300;\n  color: ", ";\n"], ["\n  ", "\n  font-size: ", "px;\n  line-height: 16px;\n  font-style: normal;\n  font-weight: 300;\n  color: ", ";\n"])), exports.primaryFont, variables_1.fontSizeBody3, colors_1.mutedGrey);
exports.typographyCondensed = (0, react_1.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  line-height: 120%;\n"], ["\n  line-height: 120%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
