import React, { useCallback } from "react";
import { pick } from "lodash";
import { useMutation } from "@apollo/client";
import { CategoryInput, Category } from "shared";
import { ParseError } from "src/lib";
import { Modal } from "client-lib";
import { CategoryForm } from "./category-form";
import {
  UpdateCategory,
  UpdateCategoryVars,
  UpdateCategoryData,
} from "../queries";

interface Props {
  category: Category;
  onCloseRequest: () => any;
  onUpdated?: () => any;
}

export function UpdateCategoryModal({
  category,
  onCloseRequest,
  onUpdated,
}: Props) {
  const [update, { loading, error }] = useMutation<
    UpdateCategoryData,
    UpdateCategoryVars
  >(UpdateCategory);
  const handleSubmit = useCallback(
    async (input: CategoryInput) => {
      await update({
        variables: {
          id: category.id,
          patch: pick(input, "name", "parentId", "iconId"),
        },
      });
      onUpdated?.();
      onCloseRequest();
    },
    [update, onCloseRequest, category.id, onUpdated]
  );

  return (
    <Modal onCloseRequest={onCloseRequest} title="Edit category">
      {error ? <ParseError error={error} /> : null}
      <CategoryForm
        initialValues={category}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle="Save"
      />
    </Modal>
  );
}
