import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { OrdersPage } from "./orders-page";
import { OrderPage } from "./order-page";
import { OrderLineItemPage } from "./order-line-item-page";

export function OrdersRouter() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={OrdersPage} />
      <Route exact path={`${path}/:orderId`} component={OrderPage} />
      <Route
        exact
        path={`${path}/:orderId/:itemId`}
        component={OrderLineItemPage}
      />
    </Switch>
  );
}
