"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.hairlineTopAndBottom = exports.hairlineBottom = exports.hairlineRight = exports.hairline = exports.insetBorder = void 0;
var react_1 = require("@emotion/react");
var colors_1 = require("./colors");
var insetBorder = function (width, color) {
    if (width === void 0) { width = 0.5; }
    if (color === void 0) { color = (0, colors_1.withAlpha)(colors_1.trueBlack, 0.1); }
    return "\n  box-shadow: inset 0 0 0 ".concat(width, "px ").concat(color, ";\n");
};
exports.insetBorder = insetBorder;
var defaultBorderOptions = {
    color: colors_1.borderGrey,
    radius: 0,
    sides: ["top", "right", "bottom", "left"],
    otherBorders: [],
    width: 0.5,
};
var hairline = function (options) {
    if (options === void 0) { options = defaultBorderOptions; }
    var withDefaults = __assign(__assign({}, defaultBorderOptions), options);
    var width = withDefaults.width;
    var offset = width * 2;
    var shadows = withDefaults.sides
        .map(function (side) {
        switch (side) {
            case "top":
                return "inset 0 ".concat(offset, "px 0 -").concat(width, "px");
            case "right":
                return "inset -".concat(offset, "px 0 0 -").concat(width, "px");
            case "bottom":
                return "inset 0 -".concat(offset, "px 0 -").concat(width, "px");
            case "left":
                return "inset ".concat(offset, "px 0 0 -").concat(width, "px");
            default:
                throw new Error("Unknown BoxSide \"".concat(side, "\""));
        }
    })
        .map(function (shadowString) { return "".concat(shadowString, " ").concat(withDefaults.color); });
    var loDpiShadows = withDefaults.sides
        .map(function (side) {
        var loDpiWidth = Math.ceil(width);
        var loDpiOffset = Math.ceil(loDpiWidth * 2);
        switch (side) {
            case "top":
                return "inset 0 ".concat(loDpiOffset, "px 0 -").concat(loDpiWidth, "px");
            case "right":
                return "inset -".concat(loDpiOffset, "px 0 0 -").concat(loDpiWidth, "px");
            case "bottom":
                return "inset 0 -".concat(loDpiOffset, "px 0 -").concat(loDpiWidth, "px");
            case "left":
                return "inset ".concat(loDpiOffset, "px 0 0 -").concat(loDpiWidth, "px");
            default:
                throw new Error("Unknown BoxSide \"".concat(side, "\""));
        }
    })
        .map(function (shadowString) { return "".concat(shadowString, " ").concat(withDefaults.color); });
    var radius = withDefaults.radius
        ? "border-radius: ".concat(withDefaults.radius, "px;")
        : "";
    return "\n    box-shadow: ".concat(__spreadArray(__spreadArray([], (withDefaults.otherBorders || []), true), shadows, true).join(", "), ";\n    ").concat(radius, "\n\n    @media (max-resolution: 1dppx), (-webkit-max-device-pixel-ratio: 1) {\n      box-shadow: ").concat(__spreadArray(__spreadArray([], (withDefaults.otherBorders || []), true), loDpiShadows, true).join(", "), ";\n    }");
};
exports.hairline = hairline;
exports.hairlineRight = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), (0, exports.hairline)({ sides: ["right"] }));
exports.hairlineBottom = (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), (0, exports.hairline)({ sides: ["bottom"] }));
exports.hairlineTopAndBottom = (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), (0, exports.hairline)({ sides: ["top", "bottom"] }));
var templateObject_1, templateObject_2, templateObject_3;
