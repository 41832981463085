import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { Button, Spinner, gutters, useBoolean } from "client-lib";
import styled from "@emotion/styled";
import { ParseError } from "../../lib";
import { Layout } from "../layout/index";
import { Clubs, ClubsData, ClubsVars } from "./queries";
import { ClubList } from "./club-list";
import { CreateClubModal } from "./club-form/create-club-modal";

const ButtonContainer = styled.div`
  margin: ${gutters.md}px 0px;
`;

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <h1>Clubs</h1>
      {children}
    </Layout>
  );
}

export function ClubsPage() {
  const [isCreateClubOpen, { set: openCreateClub, reset: closeCreateClub }] =
    useBoolean(false);

  const { data, loading, error, refetch } = useQuery<ClubsData, ClubsVars>(
    Clubs,
    {
      variables: {
        categoryIds: null,
      },
    }
  );

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <ButtonContainer>
        <Button onClick={openCreateClub}>Create New Club</Button>
      </ButtonContainer>
      {isCreateClubOpen && (
        <CreateClubModal onCloseRequest={closeCreateClub} onCreated={refetch} />
      )}
      <ClubList clubs={data.Clubs} />
    </Wrapper>
  );
}
