"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoSubmitter = void 0;
var react_1 = require("react");
var formik_1 = require("formik");
function AutoSubmitter(_a) {
    var _b = _a.timeout, timeout = _b === void 0 ? 500 : _b;
    var initialValuesHandledRef = (0, react_1.useRef)(false);
    var timerRef = (0, react_1.useRef)(null);
    var _c = (0, formik_1.useFormikContext)(), submitForm = _c.submitForm, values = _c.values, initialValues = _c.initialValues, isValid = _c.isValid;
    (0, react_1.useEffect)(function resetOnInitialValuesChange() {
        initialValuesHandledRef.current = false;
    }, [initialValues]);
    (0, react_1.useEffect)(function launchTimerOnValuesChange() {
        if (!initialValuesHandledRef.current) {
            initialValuesHandledRef.current = true;
            return;
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(submitForm, timeout);
    }, [values, isValid, timeout, submitForm]);
    return null;
}
exports.AutoSubmitter = AutoSubmitter;
