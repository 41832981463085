"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BadUserInputError = exports.UnauthenticatedError = exports.ForbiddenError = exports.NotFoundError = exports.ErrorCode = void 0;
var ErrorCode;
(function (ErrorCode) {
    ErrorCode["UNAUTHENTICATED"] = "UNAUTHENTICATED";
    ErrorCode["FORBIDDEN"] = "FORBIDDEN";
    ErrorCode["BAD_USER_INPUT"] = "BAD_USER_INPUT";
    ErrorCode["GRAPHQL_VALIDATION_FAILED"] = "GRAPHQL_VALIDATION_FAILED";
    // project specific errors
    ErrorCode["NOT_FOUND"] = "NOT_FOUND";
    ErrorCode["VERIFICATION"] = "VERIFICATION";
})(ErrorCode = exports.ErrorCode || (exports.ErrorCode = {}));
class NotFoundError extends Error {
    constructor(message) {
        super(message);
        this.message = message;
        this.name = ErrorCode.NOT_FOUND;
    }
}
exports.NotFoundError = NotFoundError;
class ForbiddenError extends Error {
    constructor(message = "Forbidden") {
        super(message);
        this.message = message;
        this.name = ErrorCode.FORBIDDEN;
    }
}
exports.ForbiddenError = ForbiddenError;
class UnauthenticatedError extends Error {
    constructor(message = "UNAUTHENTICATED") {
        super(message);
        this.message = message;
        this.name = ErrorCode.UNAUTHENTICATED;
    }
}
exports.UnauthenticatedError = UnauthenticatedError;
class BadUserInputError extends Error {
    constructor(message = "BadUserInput") {
        super(message);
        this.message = message;
        this.name = ErrorCode.BAD_USER_INPUT;
    }
}
exports.BadUserInputError = BadUserInputError;
