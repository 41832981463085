import React, { useCallback, useMemo } from "react";
import { pick } from "lodash";
import { useMutation } from "@apollo/client";
import { Order, OrderPatch } from "shared";
import { ParseError } from "src/lib";
import { Modal } from "client-lib";
import { UpdateOrder, UpdateOrderVars, UpdateOrderData } from "../queries";
import { OrderForm } from "./order-form";

interface Props {
  order: Order;
  onCloseRequest: () => void;
  onUpdated?: () => void;
}

export function UpdateOrderModal({ order, onCloseRequest, onUpdated }: Props) {
  const [update, { loading, error }] = useMutation<
    UpdateOrderData,
    UpdateOrderVars
  >(UpdateOrder);

  const initialValues = useMemo<OrderPatch>(
    () => pick(order, "status", "financialStatus") as OrderPatch,
    [order]
  );

  const handleSubmit = useCallback(
    async (patch: OrderPatch) => {
      await update({
        variables: {
          id: order.id,
          patch,
        },
      });
      onUpdated?.();
      onCloseRequest();
    },
    [update, onUpdated, onCloseRequest, order]
  );

  return (
    <Modal onCloseRequest={onCloseRequest} title="Edit order" width={800}>
      {error ? <ParseError error={error} /> : null}
      <OrderForm
        initialValues={initialValues}
        disabled={loading}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}
