"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.locationEnvironmentSchema = exports.locationSchema = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
exports.locationSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    name: zod_1.z.string(),
    level: zod_1.z.number().int().positive(),
    directParentId: zod_1.z.string().uuid().nullable(),
    originalZirklId: zod_1.z.number().int().positive().nullable(),
    childrenCount: zod_1.z.number().int().nonnegative(),
});
(0, services_1.checkSchema)(exports.locationSchema);
exports.locationEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    location: exports.locationSchema,
    parents: zod_1.z.array(exports.locationSchema),
    children: zod_1.z.array(exports.locationSchema),
});
