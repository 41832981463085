"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.feedItemEventInputSchema = exports.feedItemImpressionInputSchema = exports.feedItemNewsInputSchema = exports.feedItemInputSchema = exports.feedItemEnvironmentSchema = exports.feedResultSchema = exports.feedItemEnvironmentLightSchema = exports.feedItemSchema = exports.feedItemTypeSchema = exports.FeedItemType = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
const content_visibility_1 = require("./content-visibility");
const club_post_1 = require("./club-post");
const club_event_1 = require("./club-event");
const participation_1 = require("./participation");
var FeedItemType;
(function (FeedItemType) {
    FeedItemType["NEWS"] = "NEWS";
    FeedItemType["IMPRESSION"] = "IMPRESSION";
    FeedItemType["EVENT"] = "EVENT";
})(FeedItemType = exports.FeedItemType || (exports.FeedItemType = {}));
exports.feedItemTypeSchema = zod_1.z.nativeEnum(FeedItemType);
exports.feedItemSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    type: exports.feedItemTypeSchema,
    publishedAt: zod_1.z.date(),
    clubId: zod_1.z.string().uuid().nullable(),
    clubPostId: zod_1.z.string().uuid().nullable(),
    // * ordinalId is automatically generated by the database, it is optional in the schema so that
    // * it can be omitted when creating a new feed item from the front-end
    ordinalId: zod_1.z.number().int().positive().optional(),
    visibility: content_visibility_1.contentVisibilitySchema,
});
(0, services_1.checkSchema)(exports.feedItemSchema);
exports.feedItemEnvironmentLightSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    feedItem: exports.feedItemSchema,
    clubEvent: club_event_1.clubEventSchema.nullable(),
    clubPostEnvironmentLight: club_post_1.clubPostEnvironmentLightSchema,
});
exports.feedResultSchema = zod_1.z.object({
    cursor: zod_1.z.number().int().positive().nullable(),
    items: zod_1.z.array(exports.feedItemEnvironmentLightSchema),
});
exports.feedItemEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    feedItem: exports.feedItemSchema,
    clubEvent: club_event_1.clubEventSchema.nullable(),
    clubPostEnvironment: club_post_1.clubPostEnvironmentSchema,
    participation: participation_1.participationSchema.nullable(),
});
// General input schema, the one used for GraphQL
exports.feedItemInputSchema = exports.feedItemSchema
    .pick({
    id: true,
    type: true,
    publishedAt: true,
    visibility: true,
})
    .extend({
    clubPost: club_post_1.clubPostInputSchema,
});
// specific schemas used in Formik and run-time validation
exports.feedItemNewsInputSchema = exports.feedItemInputSchema
    .omit({
    clubPost: true,
})
    .extend({
    clubPost: club_post_1.newsInputSchema,
});
exports.feedItemImpressionInputSchema = exports.feedItemInputSchema
    .omit({
    clubPost: true,
})
    .extend({
    clubPost: club_post_1.impressionInputSchema,
});
exports.feedItemEventInputSchema = exports.feedItemInputSchema
    .omit({
    clubPost: true,
})
    .extend({
    clubPost: club_post_1.eventInputSchema,
});
