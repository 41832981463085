import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { Spinner } from "client-lib";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import { Locations, LocationsData, LocationsVars } from "./queries";
import { LocationList } from "./location-list";

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <h1>Locations</h1>
      {children}
    </Layout>
  );
}

export function LocationsPage() {
  const { data, loading, error } = useQuery<LocationsData, LocationsVars>(
    Locations,
    {
      variables: {
        parentId: null,
      },
    }
  );

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <LocationList locations={data.Locations} />
    </Wrapper>
  );
}
