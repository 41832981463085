"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntField = void 0;
var formik_field_wrapper_1 = require("./formik-field-wrapper");
var styles_1 = require("./styles");
exports.IntField = (0, formik_field_wrapper_1.withField)(styles_1.StyledInput, {
    translateInputValue: function (rawInputValue) {
        if (rawInputValue === "") {
            return null;
        }
        var numericValue = Number(rawInputValue);
        if (Number.isNaN(numericValue) ||
            parseInt(rawInputValue, 10) !== numericValue) {
            return rawInputValue;
        }
        return numericValue;
    },
});
