import { useLocation } from "react-router-dom";

export function constructLoginUrl(
  location: ReturnType<typeof useLocation>
): string {
  const { pathname, search } = location;
  const redirectTo = `${pathname}${search}`;

  return redirectTo && redirectTo !== "/"
    ? `/login?redirect=${encodeURIComponent(redirectTo)}`
    : "/login";
}
