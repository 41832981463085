import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { ClubsPage } from "./clubs-page";
import { ClubPage } from "./club-page";

export function ClubRouter() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ClubsPage} />
      <Route exact path={`${path}/:clubId`} component={ClubPage} />
    </Switch>
  );
}
