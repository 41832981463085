import React, { ReactNode, useState } from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Icon } from "shared";
import { Spinner, grey20 } from "client-lib";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import { Icons, IconsData, IconsVars } from "./queries";
import { IconItem } from "./icon-item";
import { NewIcon, UpdateIconModal } from "./icon-form";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ItemWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  :hover {
    background: ${grey20};
  }
`;

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <h1>Icons</h1>
      {children}
    </Layout>
  );
}

export function IconsPage() {
  const [iconToEdit, setIconToEdit] = useState<Icon | null>(null);
  const { data, loading, error, refetch } = useQuery<IconsData, IconsVars>(
    Icons
  );

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <Container>
        {data.Icons.map((icon) => (
          <ItemWrapper key={icon.id} onClick={() => setIconToEdit(icon)}>
            <IconItem svg={icon.svg} />
          </ItemWrapper>
        ))}
      </Container>
      <h1>Create new icon</h1>
      <NewIcon onCompleted={refetch} />
      {iconToEdit && (
        <UpdateIconModal
          icon={iconToEdit}
          onCloseRequest={() => {
            setIconToEdit(null);
          }}
        />
      )}
    </Wrapper>
  );
}
