import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { IconsPage } from "./icons-page";

export function IconRouter() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={IconsPage} />
    </Switch>
  );
}
