"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventInputSchema = exports.impressionInputSchema = exports.newsInputSchema = exports.clubPostInputSchema = exports.clubPostEnvironmentSchema = exports.clubPostEnvironmentLightSchema = exports.clubPostSchema = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
const content_category_1 = require("./content-category");
const club_post_file_1 = require("./club-post-file");
const user_1 = require("../user");
const club_1 = require("../club");
const club_event_1 = require("./club-event");
const link_with_description_1 = require("./link-with-description");
exports.clubPostSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    clubId: zod_1.z.string().uuid(),
    title: zod_1.z.string(),
    description: zod_1.z.string().nullable(),
    imageId: zod_1.z.string().uuid().nullable(),
    links: zod_1.z.array(link_with_description_1.linkWithDescriptionSchema).nullable(),
    allowReactions: zod_1.z.boolean(),
    allowComments: zod_1.z.boolean(),
    allowShare: zod_1.z.boolean(),
    responsibleUserId: zod_1.z.string().uuid(),
});
(0, services_1.checkSchema)(exports.clubPostSchema);
exports.clubPostEnvironmentLightSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    clubPost: exports.clubPostSchema,
    club: club_1.clubSchema,
    clubEvent: club_event_1.clubEventSchema.nullable(),
    images: zod_1.z.array(club_post_file_1.clubPostFileSchema),
});
exports.clubPostEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    clubPost: exports.clubPostSchema,
    club: club_1.clubSchema,
    categories: zod_1.z.array(content_category_1.contentCategorySchema),
    responsibleUser: user_1.userSchema,
    images: zod_1.z.array(club_post_file_1.clubPostFileSchema),
    documents: zod_1.z.array(club_post_file_1.clubPostFileSchema),
});
// General input schema, the one used for GraphQL
exports.clubPostInputSchema = exports.clubPostSchema
    .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
})
    .extend({
    categories: zod_1.z.array(content_category_1.contentCategoryInputSchema),
    documents: zod_1.z.array(club_post_file_1.clubPostFileInputSchema),
    images: zod_1.z.array(club_post_file_1.clubPostFileInputSchema),
    clubEvent: club_event_1.clubEventInputSchema.nullable(),
});
// specific schemas used in Formik and run-time validation
exports.newsInputSchema = exports.clubPostInputSchema
    .pick({
    // id: true,
    title: true,
    imageId: true,
    clubId: true,
    links: true,
    allowReactions: true,
    allowComments: true,
    allowShare: true,
    responsibleUserId: true,
    categories: true,
    documents: true,
})
    .extend({
    // Unlike basic schema, the description is required
    description: zod_1.z.string(),
});
exports.impressionInputSchema = exports.newsInputSchema
    .omit({
    imageId: true,
    categories: true,
    documents: true,
    description: true,
})
    .extend({
    images: zod_1.z.array(club_post_file_1.clubPostFileInputSchema).min(1),
    // Unlike basic schema, the description is required
    description: zod_1.z.string(),
});
exports.eventInputSchema = exports.clubPostInputSchema
    .pick({
    // id: true,
    title: true,
    imageId: true,
    clubId: true,
    links: true,
    allowReactions: true,
    allowComments: true,
    allowShare: true,
    responsibleUserId: true,
    categories: true,
    documents: true,
})
    .extend({
    // Unlike basic schema, the description is required
    description: zod_1.z.string(),
    clubEvent: club_event_1.clubEventInputSchema,
});
