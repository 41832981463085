import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Breadcrumbs,
  Spinner,
  TBody,
  TR,
  TH,
  TD,
  Table,
  gutters,
  Button,
  useBoolean,
} from "client-lib";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import {
  OrderEnvironmentData,
  OrderEnvironmentVars,
  OrderEnvironmentQuery,
} from "./queries";
import { UpdateOrderModal } from "./order-form/update-order-modal";
import { OrderLineItemList } from "./order-line-item-list";

const Controls = styled.div`
  margin-bottom: ${gutters.xl}px;
  padding-top: ${gutters.md}px;
  display: flex;
  flex-direction: row;
  gap: ${gutters.sm}px;
`;

function Wrapper({
  children,
  orderId,
}: {
  children?: ReactNode;
  orderId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[{ title: "Orders", url: "/orders" }, { title: orderId }]}
      />
      <h1>Order {orderId}</h1>
      {children}
    </Layout>
  );
}

export function OrderPage() {
  const { params } = useRouteMatch<{ orderId: string }>();
  const { data, loading, error, refetch } = useQuery<
    OrderEnvironmentData,
    OrderEnvironmentVars
  >(OrderEnvironmentQuery, {
    variables: {
      orderId: params.orderId,
    },
  });
  const [isUpdateOrderOpen, { set: openUpdateOrder, reset: closeUpdateOrder }] =
    useBoolean(false);

  if (loading) {
    return (
      <Wrapper orderId={params.orderId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper orderId={params.orderId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper orderId={params.orderId} />;
  }
  const { user, order, orderLineItems } = data.OrderEnvironment;

  return (
    <Wrapper orderId={params.orderId}>
      <Controls>
        <Button color="secondary" onClick={openUpdateOrder}>
          Edit Order
        </Button>
      </Controls>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>Order ID</TH>
            <TD>{order.id}</TD>
          </TR>
          <TR>
            <TH>Created at</TH>
            <TD>{new Date(order.createdAt).toLocaleString()}</TD>
          </TR>
          <TR>
            <TH>updated at</TH>
            <TD>{new Date(order.updatedAt).toLocaleString()}</TD>
          </TR>
          <TR>
            <TH>Order status</TH>
            <TD>
              {order.status.substring(0, 1) +
                order.status.substring(1).toLowerCase()}
            </TD>
          </TR>
          <TR>
            <TH>Finanical status</TH>
            <TD>
              {order.financialStatus.substring(0, 1) +
                order.financialStatus.substring(1).toLowerCase()}
            </TD>
          </TR>
          <TR>
            <TH>User ID</TH>
            <TD>{user.id}</TD>
          </TR>
          <TR>
            <TH>First name</TH>
            <TD>{user.nameFirst}</TD>
          </TR>
          <TR>
            <TH>Last Name</TH>
            <TD>{user.nameLast}</TD>
          </TR>
        </TBody>
      </Table>

      <h1>Ordered items</h1>

      <OrderLineItemList orderId={params.orderId} items={orderLineItems} />

      {isUpdateOrderOpen && (
        <UpdateOrderModal
          order={order}
          onCloseRequest={closeUpdateOrder}
          onUpdated={refetch}
        />
      )}
    </Wrapper>
  );
}

/*
INSERT INTO "Order" ("id", "status", "financialStatus", "userId") values ('5235826a-3035-4531-81bd-6bcd01aa4453', 'ACTUAL', 'PAID', 'ecaabbe5-ff97-42e4-b16b-c824df42094a');
INSERT INTO "OrderLineItem" ("id", "type", "orderId", "userId", "clubId", "periodSeconds") values ('35f14f9c-baa4-4aa8-bb3d-87b4adf47a56', 'INVISIBILITY_FEATURE', '5235826a-3035-4531-81bd-6bcd01aa4453', 'ecaabbe5-ff97-42e4-b16b-c824df42094a', null, 100000);
 */
