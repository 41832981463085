"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contentVisibilitySchema = exports.ContentVisibility = void 0;
const zod_1 = require("zod");
var ContentVisibility;
(function (ContentVisibility) {
    ContentVisibility["PUBLIC"] = "PUBLIC";
    ContentVisibility["MEMBERS"] = "MEMBERS";
    ContentVisibility["BOARD"] = "BOARD";
})(ContentVisibility = exports.ContentVisibility || (exports.ContentVisibility = {}));
exports.contentVisibilitySchema = zod_1.z.nativeEnum(ContentVisibility);
