import React from "react";
import { CategoryWithIcon } from "shared";
import { HeaderCell, HeaderRow, List, ListLinkCell, ListRow } from "src/lib";
import { IconItem } from "src/features/icon";

const columnTemplate = "0.5fr 0.3fr 0.8fr 0.1fr 0.3fr";

const defaultURL = (id: string) => `/categories/${id}`;

interface Props {
  categories: CategoryWithIcon[];
  url?: (id: string) => string;
}

export function CategoryList({ categories, url: urlProp = defaultURL }: Props) {
  return (
    <List>
      <HeaderRow columnTemplate={columnTemplate}>
        <HeaderCell>ID</HeaderCell>
        <HeaderCell center>Icon</HeaderCell>
        <HeaderCell>Name</HeaderCell>
        <HeaderCell>Clubs</HeaderCell>
        <HeaderCell>Subcategories</HeaderCell>
      </HeaderRow>
      {categories.map(({ category, icon }: CategoryWithIcon) => {
        const url = urlProp(category.id);
        return (
          <ListRow key={category.id} columnTemplate={columnTemplate}>
            <ListLinkCell to={url}>{category.id}</ListLinkCell>
            <ListLinkCell center to={url}>
              {icon && <IconItem svg={icon.svg} size={40} />}
            </ListLinkCell>
            <ListLinkCell to={url}>{category.name}</ListLinkCell>
            <ListLinkCell to={url}>{category.clubCount}</ListLinkCell>
            <ListLinkCell to={url}>{category.childrenCount}</ListLinkCell>
          </ListRow>
        );
      })}
    </List>
  );
}
