import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { FilesPage } from "./files-page";
import { FilePage } from "./file-page";

export function FileRouter() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={FilesPage} />
      <Route exact path={`${path}/:fileId`} component={FilePage} />
    </Switch>
  );
}
