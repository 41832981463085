import gql from "graphql-tag";
import { User, UserAdminPatch, deviceSchema, Device } from "shared";
import { USER_FULL_FRAGMENT, getFieldsFromSchema } from "client-lib";

export const DEVICE_FULL_FRAGMENT = gql`
  fragment DeviceFullFragment on Device {
    ${getFieldsFromSchema(deviceSchema)}
  }
`;

export const Users = gql`
  query Users {
    Users {
      ...UserFullFragment
    }
  }
  ${USER_FULL_FRAGMENT}
`;

export interface UsersVars {}

export type UsersData = {
  Users: User[];
};

export const UserEnvironmentQuery = gql`
  query UserEnvironment($userId: String!) {
    UserEnvironment(userId: $userId) {
      user {
        ...UserFullFragment
      }
    }
    AdminDevices(userId: $userId) {
      ...DeviceFullFragment
    }
  }
  ${USER_FULL_FRAGMENT}
  ${DEVICE_FULL_FRAGMENT}
`;

export interface UserEnvironmentVars {
  userId: string;
}

export type UserEnvironmentData = {
  UserEnvironment: {
    user: User;
  };
  AdminDevices: Device[];
};

export const AdminDevice = gql`
  query AdminDevice($userId: String!, $deviceId: String!) {
    AdminDevice(deviceId: $deviceId) {
      ...DeviceFullFragment
    }
    UserEnvironment(userId: $userId) {
      user {
        ...UserFullFragment
      }
    }
  }
  ${DEVICE_FULL_FRAGMENT}
  ${USER_FULL_FRAGMENT}
`;

export interface AdminDeviceVars {
  deviceId: string;
  userId: string;
}

export type AdminDeviceData = {
  AdminDevice: Device;
  UserEnvironment: {
    user: User;
  };
};

export const UserToken = gql`
  query UserToken($userId: String!) {
    UserToken(userId: $userId)
  }
`;

export interface UserTokenVars {
  userId: string;
}

export type UserTokenData = {
  UserToken: string;
};

export const AdminUpdateUser = gql`
  mutation AdminUpdateUser($id: String!, $patch: UserAdminPatch!) {
    AdminUpdateUser(id: $id, patch: $patch) {
      ...UserFullFragment
    }
  }
  ${USER_FULL_FRAGMENT}
`;

export interface AdminUpdateUserVars {
  id: string;
  patch: UserAdminPatch;
}

export type AdminUpdateUserData = {
  AdminUpdateUser: User;
};

export const AdminDeleteUser = gql`
  mutation DeleteUser($id: String!) {
    AdminDeleteUser(id: $id) {
      ...UserFullFragment
    }
  }
  ${USER_FULL_FRAGMENT}
`;

export type AdminDeleteUserVars = {
  id: string;
};

export type AdminDeleteUserData = {
  AdminDeleteUser: User;
};

export const AdminSendNotification = gql`
  mutation AdminSendNotification(
    $deviceId: String!
    $title: String!
    $body: String!
    $link: String
  ) {
    AdminSendNotification(
      deviceId: $deviceId
      title: $title
      body: $body
      link: $link
    )
  }
`;

export type AdminSendNotificationVars = {
  deviceId: string;
  title: string;
  body: string;
  link: string | null;
};

export type AdminSendNotificationData = {
  AdminSendNotification: string | null;
};
