import React from "react";
import { Route, RouteProps, Redirect, useLocation } from "react-router-dom";
import { useAuth } from "./auth-context";
import { constructLoginUrl } from "./service";

type Props = RouteProps;

export function AuthRoute(props: Props) {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();
  const isAuthenticatedAdmin = isAuthenticated && user?.isAdmin;

  return isAuthenticatedAdmin ? (
    <Route {...props} />
  ) : (
    <Redirect to={constructLoginUrl(location)} />
  );
}
