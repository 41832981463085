import React, { memo } from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  svg: string;
  size?: number;
}

export const IconItem = memo(({ svg, size = 50 }: Props) => {
  return (
    <Container
      dangerouslySetInnerHTML={{ __html: svg }}
      style={{ width: size, height: size }}
    />
  );
});
