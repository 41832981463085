import React, { useRef } from "react";
import { ApolloClient, ApolloProvider as Provider } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import { createClient } from "./create-client";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export function ApolloProvider({ children }: Props) {
  const location = useLocation();
  const history = useHistory();
  const client = useRef<ApolloClient<{}>>(createClient(history, location));

  return <Provider client={client.current}>{children}</Provider>;
}
