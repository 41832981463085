import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { CategoriesPage } from "./categories-page";
import { CategoryPage } from "./category-page";

export function CategoryRouter() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={CategoriesPage} />
      <Route exact path={`${path}/:categoryId`} component={CategoryPage} />
    </Switch>
  );
}
