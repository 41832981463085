import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { Order } from "shared";
import { Spinner } from "client-lib";
import {
  HeaderCell,
  HeaderRow,
  List,
  ListLinkCell,
  ListRow,
  ParseError,
  cutId,
} from "src/lib";
import { Layout } from "src/features/layout";
import { Orders, OrdersData, OrdersVars } from "./queries";

const columnTemplate = "1fr 1.2fr 1.2fr 1fr 1fr 0.5fr";

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <h1>Orders</h1>
      {children}
    </Layout>
  );
}

export function OrdersPage() {
  const { data, loading, error } = useQuery<OrdersData, OrdersVars>(Orders);

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <List>
        <HeaderRow columnTemplate={columnTemplate}>
          <HeaderCell>ID</HeaderCell>
          <HeaderCell>Created</HeaderCell>
          <HeaderCell>Last updated</HeaderCell>
          <HeaderCell>Status</HeaderCell>
          <HeaderCell>Financial Status</HeaderCell>
          <HeaderCell>User ID</HeaderCell>
        </HeaderRow>
        {data.Orders.map((order: Order) => {
          const url = `/orders/${order.id}`;
          return (
            <ListRow key={order.id} columnTemplate={columnTemplate}>
              <ListLinkCell to={url}>{cutId(order.id)}</ListLinkCell>
              <ListLinkCell to={url}>
                {new Date(order.createdAt).toLocaleString()}
              </ListLinkCell>
              <ListLinkCell to={url}>
                {new Date(order.updatedAt).toLocaleString()}
              </ListLinkCell>
              <ListLinkCell to={url}>
                {order.status.substring(0, 1) + order.status.substring(1)}
              </ListLinkCell>
              <ListLinkCell to={url}>
                {order.financialStatus.substring(0, 1) +
                  order.financialStatus.substring(1).toLowerCase()}
              </ListLinkCell>
              <ListLinkCell to={url}>{cutId(order.userId)}</ListLinkCell>
            </ListRow>
          );
        })}
      </List>
    </Wrapper>
  );
}
