import React from "react";
import styled from "@emotion/styled";
import { IconInput } from "shared";
import {
  Button,
  TextField,
  MultilineTextField,
  gutters,
  grey30,
} from "client-lib";
import { Form, Formik, FormikProps } from "formik";
import { iconInputFormikSchema } from "./types";
import { IconItem } from "../icon-item";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

const MetaBlock = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  initialValues: IconInput;
  onSubmit: (values: IconInput) => any;
  disabled?: boolean;
  submitTitle?: string;
}

export function IconForm({
  initialValues,
  onSubmit,
  disabled,
  submitTitle,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={iconInputFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, isValid, dirty }: FormikProps<IconInput>) => (
        <Form>
          <MetaBlock>
            <TextField
              label="Meta information"
              controlProps={{ size: 50 }}
              disabled={disabled}
              name="meta"
            />
            <IconItem svg={values.svg} />
          </MetaBlock>
          <MultilineTextField
            label="SVG"
            rows={15}
            disabled={disabled}
            name="svg"
          />
          <Controls>
            <Button type="submit" disabled={disabled || !isValid || !dirty}>
              {submitTitle || "Update"}
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
