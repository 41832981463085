"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBoolean = void 0;
var react_1 = require("react");
function useBoolean(initialValue) {
    if (initialValue === void 0) { initialValue = false; }
    var _a = (0, react_1.useState)(initialValue), value = _a[0], setValue = _a[1];
    var setTrue = (0, react_1.useRef)(function () { return setValue(true); }).current;
    var setFalse = (0, react_1.useRef)(function () { return setValue(false); }).current;
    var toggle = (0, react_1.useRef)(function () { return setValue(function (was) { return !was; }); }).current;
    return [value, { setTrue: setTrue, setFalse: setFalse, set: setTrue, reset: setFalse, toggle: toggle }];
}
exports.useBoolean = useBoolean;
