import React from "react";
import { isEmpty } from "lodash";
import styled from "@emotion/styled";
import { OrderLineItemPatch } from "shared";
import { Button, gutters, grey30, IntField, TextField } from "client-lib";
import { Form, Formik, FormikProps } from "formik";
import { orderLineItemPatchFormikSchema } from "./types";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

const Error = styled.div`
  margin-top: ${gutters.sm}px;
  padding-top: ${gutters.sm}px;
  color: red;
`;

interface Props {
  initialValues: OrderLineItemPatch;
  disabled: boolean;
  onSubmit: (values: OrderLineItemPatch) => any;
}

export function OrderLineItemForm({
  initialValues,
  disabled,
  onSubmit,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={orderLineItemPatchFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty, errors }: FormikProps<OrderLineItemPatch>) => (
        <Form>
          <IntField name="periodSeconds" label="Valid for (seconds)" />
          <TextField name="userId" label="User ID" />
          <TextField name="clubId" label="Club ID" />
          <br />
          <Error>{errors && !isEmpty(errors) && JSON.stringify(errors)}</Error>
          <Controls>
            <Button type="submit" disabled={disabled || !isValid || !dirty}>
              Save
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
