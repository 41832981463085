"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iconPatchSchema = exports.iconInputSchema = exports.iconSchema = void 0;
const zod_1 = require("zod");
const services_1 = require("../services");
exports.iconSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    svg: zod_1.z.string(),
    meta: zod_1.z.string().nullable(),
});
(0, services_1.checkSchema)(exports.iconSchema);
exports.iconInputSchema = exports.iconSchema.pick({
    id: true,
    svg: true,
    meta: true,
});
exports.iconPatchSchema = exports.iconSchema.pick({
    svg: true,
    meta: true,
});
