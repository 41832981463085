import React from "react";
import { OrderLineItem } from "shared";
import { HeaderCell, HeaderRow, List, ListLinkCell, ListRow } from "src/lib";
import { DAYTOSECONDS, SECONDTOMILLISECONDS } from "src";

const columnTemplate = "0.7fr 0.5fr 0.4fr 0.4fr 0.4fr 0.4fr";

const defaultURL = (orderId: string, itemId: string) =>
  `/orders/${orderId}/${itemId}`;

interface Props {
  orderId: string;
  items: OrderLineItem[];
  url?: (orderId: string, itemId: string) => string;
}

export function OrderLineItemList({
  orderId,
  items,
  url: urlProp = defaultURL,
}: Props) {
  return (
    <List>
      <HeaderRow columnTemplate={columnTemplate}>
        <HeaderCell>ID</HeaderCell>
        <HeaderCell>Item name</HeaderCell>
        <HeaderCell>Created at</HeaderCell>
        <HeaderCell>Last updated</HeaderCell>
        <HeaderCell>Granted period (days)</HeaderCell>
        <HeaderCell>Expires</HeaderCell>
      </HeaderRow>
      {items.map((item) => {
        const url = urlProp(orderId, item.id);
        return (
          <ListRow key={item.id} columnTemplate={columnTemplate}>
            <ListLinkCell to={url}>{item.id}</ListLinkCell>
            <ListLinkCell to={url}>{parseLineItemType(item.type)}</ListLinkCell>
            <ListLinkCell to={url}>
              {new Date(item.createdAt).toLocaleString()}
            </ListLinkCell>
            <ListLinkCell to={url}>
              {new Date(item.updatedAt).toLocaleString()}
            </ListLinkCell>
            <ListLinkCell to={url}>
              {item.periodSeconds
                ? `${Math.floor(item.periodSeconds / DAYTOSECONDS)} days`
                : "Order line Item has no period"}
            </ListLinkCell>
            <ListLinkCell to={url}>
              {item.periodSeconds
                ? new Date(
                    new Date(item.createdAt).getTime() +
                      (item.periodSeconds ? item.periodSeconds : 0) *
                        SECONDTOMILLISECONDS
                  ).toLocaleString()
                : "Order line item has no period"}
            </ListLinkCell>
          </ListRow>
        );
      })}
    </List>
  );
}

function parseLineItemType(type: string) {
  return (
    type.substring(0, 1) + type.substring(1).toLowerCase().replace("_", " ")
  );
}
