import gql from "graphql-tag";

export const SignIn = gql`
  mutation SignIn($email: String!, $password: String!) {
    SignIn(email: $email, password: $password)
  }
`;

export type SignInVariables = {
  email: string;
  password: string;
};

export type SignInData = {
  SignIn: string;
};
