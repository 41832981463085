"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileSchema = void 0;
const zod_1 = require("zod");
const services_1 = require("../services");
exports.fileSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    userId: zod_1.z.string().uuid(),
    size: zod_1.z.number().int().nullable(),
    mimeType: zod_1.z.string(),
    originalFileName: zod_1.z.string(),
});
(0, services_1.checkSchema)(exports.fileSchema);
