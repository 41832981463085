"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deepUnwrap = exports.jsonSchema = exports.literalSchema = exports.getObjectFromEffects = exports.isEffects = void 0;
const zod_1 = require("zod");
function isEffects(objectOrEffects) {
    return objectOrEffects.innerType;
}
exports.isEffects = isEffects;
function getObjectFromEffects(objectOrEffects) {
    return isEffects(objectOrEffects)
        ? objectOrEffects.innerType()
        : objectOrEffects;
}
exports.getObjectFromEffects = getObjectFromEffects;
exports.literalSchema = zod_1.z.union([
    zod_1.z.string(),
    zod_1.z.number(),
    zod_1.z.boolean(),
    zod_1.z.null(),
]);
exports.jsonSchema = zod_1.z.lazy(() => zod_1.z.union([exports.literalSchema, zod_1.z.array(exports.jsonSchema), zod_1.z.record(exports.jsonSchema)]));
function deepUnwrap(zType) {
    if (zType instanceof zod_1.z.ZodNullable || zType instanceof zod_1.z.ZodOptional) {
        return deepUnwrap(zType.unwrap());
    }
    if (isEffects(zType)) {
        return deepUnwrap(zType.innerType());
    }
    return zType;
}
exports.deepUnwrap = deepUnwrap;
