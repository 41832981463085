"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clubEnvironmentSchema = void 0;
const zod_1 = require("zod");
const category_1 = require("../category");
const membership_1 = require("./membership");
const location_1 = require("../location");
const tier_1 = require("./tier");
const club_1 = require("./club");
exports.clubEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    club: club_1.clubSchema,
    categories: zod_1.z.array(category_1.categoryWithIconSchema),
    reachLocations: zod_1.z.array(location_1.locationSchema),
    location: location_1.locationSchema,
    tiers: zod_1.z.array(tier_1.tierSchema),
    membership: membership_1.membershipSchema.nullable(),
});
