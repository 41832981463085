"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.orderLineItemPatchSchema = exports.orderLineItemSchema = exports.orderLineItemUncheckedSchema = exports.orderLineItemTypeSchema = exports.OrderLineItemType = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
var OrderLineItemType;
(function (OrderLineItemType) {
    OrderLineItemType["INVISIBILITY_FEATURE"] = "INVISIBILITY_FEATURE";
    OrderLineItemType["WAITING_LIST"] = "WAITING_LIST";
})(OrderLineItemType = exports.OrderLineItemType || (exports.OrderLineItemType = {}));
exports.orderLineItemTypeSchema = zod_1.z.nativeEnum(OrderLineItemType);
exports.orderLineItemUncheckedSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    type: exports.orderLineItemTypeSchema,
    orderId: zod_1.z.string().uuid(),
    userId: zod_1.z.string().uuid().nullable(),
    clubId: zod_1.z.string().uuid().nullable(),
    periodSeconds: zod_1.z.number().int().nonnegative().nullable(),
});
(0, services_1.checkSchema)(exports.orderLineItemUncheckedSchema);
exports.orderLineItemSchema = exports.orderLineItemUncheckedSchema.refine((item) => {
    switch (item.type) {
        case OrderLineItemType.INVISIBILITY_FEATURE:
            return item.userId !== null && item.clubId === null;
        case OrderLineItemType.WAITING_LIST:
            return item.userId === null && item.clubId !== null;
    }
    return false;
}, { path: ["type"] });
exports.orderLineItemPatchSchema = exports.orderLineItemUncheckedSchema.pick({
    userId: true,
    clubId: true,
    periodSeconds: true,
});
