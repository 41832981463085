import React from "react";
import { Location } from "shared";
import { HeaderCell, HeaderRow, List, ListLinkCell, ListRow } from "src/lib";

const columnTemplate = "0.3fr 1fr";

const defaultURL = (id: string) => `/locations/${id}`;

interface Props {
  locations: Location[];
  url?: (id: string) => string;
}

export function LocationList({ locations, url: urlProp = defaultURL }: Props) {
  return (
    <List>
      <HeaderRow columnTemplate={columnTemplate}>
        <HeaderCell>ID</HeaderCell>
        <HeaderCell>Name</HeaderCell>
      </HeaderRow>
      {locations.map((location: Location) => {
        const url = urlProp(location.id);
        return (
          <ListRow key={location.id} columnTemplate={columnTemplate}>
            <ListLinkCell to={url}>{location.id}</ListLinkCell>
            <ListLinkCell to={url}>{location.name}</ListLinkCell>
          </ListRow>
        );
      })}
    </List>
  );
}
