import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link, useRouteMatch } from "react-router-dom";
import {
  black,
  white,
  grey80,
  gutters,
  typographyBody1,
  AdminLogoIcon,
  Button,
} from "client-lib";
import { useAuth } from "src/features/auth";

export const SIDEBAR_WIDTH = 200;
const HORIZONTAL_PADDING = gutters.lg;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${SIDEBAR_WIDTH}px;
  box-sizing: border-box;
  padding: ${gutters.lg}px ${HORIZONTAL_PADDING}px;
  background-color: ${black};
  color: ${white};
  a {
    text-decoration: none;
  }
`;
const LogoWrapper = styled.div`
  padding-top: 10px;
`;

const LogoutButton = styled(Button)`
  display: block;
  width: 100%;
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const Menu = styled.div`
  margin-top: ${gutters.lg}px;
  flex: 1;
`;

const StyledLink = styled(Link)<{ active: boolean }>(({ active }) => [
  typographyBody1,
  css`
    display: block;
    color: ${white};
    text-decoration: none;
    font-weight: 100;
    font-size: 16px;
    margin: 0 -${HORIZONTAL_PADDING}px;
    padding: ${gutters.sm}px ${HORIZONTAL_PADDING}px;
    background-color: ${active ? grey80 : "transparent"};
  `,
]);

function MenuLink({ to, children }: { to: string; children: ReactNode }) {
  const { path } = useRouteMatch();
  const isActive = to === "/" ? path === "/" : path.indexOf(to) === 0;
  return (
    <StyledLink to={to} active={isActive}>
      {children}
    </StyledLink>
  );
}

export function Sidebar() {
  const { logout } = useAuth();
  return (
    <Container>
      <LogoWrapper>
        <Link to="/">
          <AdminLogoIcon />
        </Link>
      </LogoWrapper>
      <Menu>
        <MenuLink to="/">Dashboard</MenuLink>
        <MenuLink to="/users">Users</MenuLink>
        <MenuLink to="/categories">Categories</MenuLink>
        <MenuLink to="/locations">Locations</MenuLink>
        <MenuLink to="/clubs">Clubs</MenuLink>
        <MenuLink to="/icons">Icons</MenuLink>
        <MenuLink to="/files">Files</MenuLink>
        <MenuLink to="/orders">Orders</MenuLink>
      </Menu>
      <LogoutButton color="transparent" onClick={logout}>
        Log out
      </LogoutButton>
    </Container>
  );
}
