import React, { useCallback } from "react";
import { v4 } from "uuid";
import { useQuery, useMutation } from "@apollo/client";
import { ParseError } from "src/lib";
import { Modal } from "client-lib";
import { ClubInput } from "shared";
import {
  Categories,
  CategoriesData,
  CategoriesVars,
} from "src/features/category/queries";
import { CreateClub, CreateClubVars, CreateClubData } from "../queries";
import { ClubForm } from "./club-form";

interface Props {
  onCloseRequest: () => any;
  onCreated?: () => any;
}

export function CreateClubModal({ onCloseRequest, onCreated }: Props) {
  const { data: categoriesData } = useQuery<CategoriesData, CategoriesVars>(
    Categories,
    {
      variables: {
        parentId: "*",
      },
    }
  );

  const [create, { loading, error }] = useMutation<
    CreateClubData,
    CreateClubVars
  >(CreateClub);

  const handleSubmit = useCallback(
    async (input: ClubInput) => {
      await create({
        variables: {
          input,
        },
      });
      onCreated?.();
      onCloseRequest();
    },
    [create, onCloseRequest, onCreated]
  );

  return (
    <Modal onCloseRequest={onCloseRequest} title="Create Club" width={800}>
      <ClubForm
        type="create"
        initialValues={{
          id: v4(),
          name: "",
          abbreviation: null,
          color: null,
          imageId: null,
          description: null,
          rules: null,
          locationId: "",
          isPublic: false,
          isActive: false,
          categoryIds: [],
          website: null,
          email: "",
          reachLocationIds: [],
        }}
        onSubmit={handleSubmit}
        disabled={loading}
        categories={categoriesData?.Categories}
      />
      {error ? <ParseError error={error} /> : null}
    </Modal>
  );
}
