"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INPUT_HEIGHT = exports.LARGE_ITEM_HEIGHT = exports.ITEM_HEIGHT = exports.SMALL_ITEM_HEIGHT = exports.HEADER_BAR_HEIGHT = exports.IconSizes = exports.APP_BAR_HEIGHT = exports.breakpointsMax = exports.breakpointsMin = exports.gutters = exports.INPUT_WIDTH = exports.zLayers = exports.COVER_VH = exports.borderRadius = exports.transitionSlow = exports.transitionFast = exports.transitionBase = exports.transitionEase = exports.transitionTime = exports.lineHeightBody3 = exports.lineHeightBody2 = exports.lineHeightBody1 = exports.fontSizeBody3 = exports.fontSizeBody2 = exports.fontSizeBody1 = exports.fontSizeH3 = exports.fontSizeH2 = exports.fontSizeH1 = void 0;
exports.fontSizeH1 = 20;
exports.fontSizeH2 = 16;
exports.fontSizeH3 = 15;
exports.fontSizeBody1 = 16;
exports.fontSizeBody2 = 14;
exports.fontSizeBody3 = 12;
exports.lineHeightBody1 = 20;
exports.lineHeightBody2 = 16;
exports.lineHeightBody3 = 14;
exports.transitionTime = 200;
exports.transitionEase = "ease-in-out";
exports.transitionBase = "".concat(exports.transitionTime, "ms ").concat(exports.transitionEase);
exports.transitionFast = "".concat(exports.transitionTime / 2, "ms ").concat(exports.transitionEase);
exports.transitionSlow = "".concat(exports.transitionTime * 2, "ms ").concat(exports.transitionEase);
exports.borderRadius = {
    sm: 2,
    md: 4,
    lg: 8,
    xl: 12,
    xxl: 16,
};
exports.COVER_VH = 80;
exports.zLayers = {
    TOASTER: 100,
    BACKDROP: 1000,
    MODAL: 20000,
};
exports.INPUT_WIDTH = 170;
exports.gutters = {
    xxs: 2,
    xs: 4,
    sm: 8,
    md: 12,
    lg: 16,
    xl: 24,
    xxl: 32,
};
exports.breakpointsMin = {
    phone: 600,
    tablet: 960,
    desktop: 1140,
};
exports.breakpointsMax = {
    phone: exports.breakpointsMin.phone - 1,
    tablet: exports.breakpointsMin.tablet - 1,
    desktop: exports.breakpointsMin.desktop - 1,
};
exports.APP_BAR_HEIGHT = 50;
exports.IconSizes = {
    XXXS: 8,
    XXS: 12,
    XS: 16,
    S: 20,
    M: 24,
    L: 28,
    XL: 32,
    XXL: 36,
    XXXL: 40,
};
exports.HEADER_BAR_HEIGHT = 48;
exports.SMALL_ITEM_HEIGHT = 21;
exports.ITEM_HEIGHT = 42;
exports.LARGE_ITEM_HEIGHT = 60;
exports.INPUT_HEIGHT = 36;
