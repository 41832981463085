"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventParticipantSchema = exports.participantsInputSchema = exports.participationInputSchema = exports.participationSchema = void 0;
const zod_1 = require("zod");
exports.participationSchema = zod_1.z.object({
    userId: zod_1.z.string().uuid(),
    clubEventId: zod_1.z.string().uuid(),
    createdAt: zod_1.z.date(),
});
exports.participationInputSchema = exports.participationSchema.omit({
    createdAt: true,
});
exports.participantsInputSchema = exports.participationSchema.omit({
    createdAt: true,
    userId: true,
});
exports.eventParticipantSchema = zod_1.z.object({
    clubEventId: zod_1.z.string().uuid(),
    userId: zod_1.z.string().uuid(),
});
