import { useMutation } from "@apollo/client";
import { Button, Modal } from "client-lib";
import React, { memo, useCallback } from "react";
import { User } from "shared";
import { ParseError } from "src/lib";
import {
  AdminDeleteUserData,
  AdminDeleteUser,
  AdminDeleteUserVars,
} from "../queries";

const STYLE = {
  display: "flex",
  gap: "10px",
  marginTop: "40px",
};

type Props = {
  user: User;
  onCloseRequest: () => void;
  onDeleted?: () => void;
};

export const DeleteUserModal = memo(function DeleteUserModal({
  user,
  onCloseRequest,
  onDeleted,
}: Props) {
  const [deleteUser, { error }] = useMutation<
    AdminDeleteUserData,
    AdminDeleteUserVars
  >(AdminDeleteUser);

  const handleSubmit = useCallback(async () => {
    await deleteUser({
      variables: {
        id: user.id,
      },
    });
    onDeleted?.();
    onCloseRequest();
  }, [deleteUser, onCloseRequest, user, onDeleted]);

  return (
    <Modal onCloseRequest={onCloseRequest} title="Delete user" minWidth={500}>
      {error ? <ParseError error={error} /> : null}
      <div>
        <h2>Are you sure you want to delete this user?</h2>
        <span>Email: </span>
        <strong>{user.email}</strong>
        <p>
          There is no going back after you confirm! Please verify the
          user&apos;s email before confirming
        </p>
        <div style={STYLE}>
          <Button color="primary" onClick={handleSubmit}>
            Delete user
          </Button>
          <Button color="secondary" onClick={onCloseRequest}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
});
