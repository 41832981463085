"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.inputStyles = void 0;
var react_1 = require("@emotion/react");
var typography_1 = require("./typography");
var colors_1 = require("./colors");
var variables_1 = require("./variables");
exports.inputStyles = [
    typography_1.typographyBody2,
    (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    outline: none;\n    box-sizing: border-box;\n    background: none;\n    width: ", "px;\n    height: ", "px;\n    line-height: 100%;\n    border: none;\n    outline: 1px solid ", ";\n    padding: 0 ", "px;\n    border-radius: ", "px;\n    background-color: ", ";\n  "], ["\n    outline: none;\n    box-sizing: border-box;\n    background: none;\n    width: ", "px;\n    height: ", "px;\n    line-height: 100%;\n    border: none;\n    outline: 1px solid ", ";\n    padding: 0 ", "px;\n    border-radius: ", "px;\n    background-color: ", ";\n  "])), variables_1.INPUT_WIDTH, variables_1.INPUT_HEIGHT, colors_1.borderGrey, variables_1.gutters.md, variables_1.borderRadius.sm, colors_1.white),
];
var templateObject_1;
