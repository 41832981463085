import React from "react";
import styled from "@emotion/styled";
import { CategoryInput } from "shared";
import { Button, TextField, gutters, grey30 } from "client-lib";
import { Form, Formik, FormikProps } from "formik";
import { IconField } from "src/features/icon";
import { categoryInputFormikSchema } from "./types";
import { CategoryField } from "../category-field";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

interface Props {
  initialValues: CategoryInput;
  onSubmit: (values: CategoryInput) => any;
  disabled?: boolean;
  submitTitle?: string;
}

export function CategoryForm({
  initialValues,
  onSubmit,
  disabled,
  submitTitle,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={categoryInputFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty }: FormikProps<CategoryInput>) => (
        <Form>
          <TextField
            label="Name"
            controlProps={{ size: 100 }}
            disabled={disabled}
            name="name"
          />
          <br />
          <IconField name="iconId" />
          <br />
          <CategoryField
            label="Parent category"
            name="parentId"
            disabled={disabled}
          />

          <Controls>
            <Button type="submit" disabled={disabled || !isValid || !dirty}>
              {submitTitle || "Save"}
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
