import React from "react";
import styled from "@emotion/styled";
import { UserAdminInput } from "shared";
import {
  Button,
  TextField,
  CheckboxField,
  DateTimeField,
  gutters,
  grey30,
} from "client-lib";
import { Form, Formik, FormikProps } from "formik";
import { userAdminInputFormikSchema } from "./types";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

interface Props {
  initialValues: UserAdminInput;
  onSubmit: (values: UserAdminInput) => any;
  disabled?: boolean;
  submitTitle?: string;
}

export function UserForm({
  initialValues,
  onSubmit,
  disabled,
  submitTitle,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={userAdminInputFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty }: FormikProps<UserAdminInput>) => (
        <Form>
          <TextField
            label="First Name"
            controlProps={{ size: 100 }}
            disabled={disabled}
            name="nameFirst"
          />
          <br />
          <TextField
            label="Last Name"
            controlProps={{ size: 100 }}
            disabled={disabled}
            name="nameLast"
          />
          <br />
          <TextField
            label="Address"
            controlProps={{ size: 100 }}
            disabled={disabled}
            name="address"
          />
          <br />
          <CheckboxField label="Is Admin?" disabled={disabled} name="isAdmin" />
          <br />
          <b>Invisibility till</b>
          <br />
          <DateTimeField hasTime disabled={disabled} name="invisibilityTill" />

          <Controls>
            <Button type="submit" disabled={disabled || !isValid || !dirty}>
              {submitTitle || "Save"}
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
