"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFieldsFromSchema = void 0;
var shared_1 = require("shared");
// Gets a list of unique fields. Does not support nested objects or arrays
function getUniqueFieldsFromUnion(zodUnion) {
    var keys = new Set();
    zodUnion.options.forEach(function (option) {
        if (!(option instanceof shared_1.z.ZodObject)) {
            throw new Error("Non-object unions are not supported");
        }
        Object.entries(option.shape).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (value instanceof shared_1.z.ZodObject || value instanceof shared_1.z.ZodArray) {
                throw new Error("Nested objects and arrays in a Union are not supported");
            }
            keys.add(key);
        });
    });
    return Array.from(keys);
}
// Gets a list of unique fields. Does not support nested objects or arrays
function getUniqueFieldsFromIntersection(zodIntersection) {
    var keys = new Set();
    var shape = __assign(__assign({}, zodIntersection._def.left.shape), zodIntersection._def.right.shape);
    Object.entries(shape).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        if (value instanceof shared_1.z.ZodObject || value instanceof shared_1.z.ZodArray) {
            throw new Error("Nested objects and arrays in a Intersction are not supported");
        }
        keys.add(key);
    });
    return Array.from(keys);
}
function getFieldsFromSchema(zodObject, patch) {
    if (patch === void 0) { patch = {}; }
    return Object.entries(zodObject.shape).reduce(function (acc, _a) {
        var key = _a[0], value = _a[1];
        if (patch[key] !== undefined) {
            return "".concat(acc).concat(patch[key]);
        }
        var internal = value instanceof shared_1.z.ZodNullable || value instanceof shared_1.z.ZodOptional
            ? value.unwrap()
            : value;
        if (internal instanceof shared_1.z.ZodArray) {
            var element = internal.element;
            if (element instanceof shared_1.z.ZodObject) {
                return "".concat(acc).concat(key, " {\n").concat(getFieldsFromSchema(element), "}\n");
            }
            if (element instanceof shared_1.z.ZodUnion) {
                var fields = getUniqueFieldsFromUnion(element);
                return "".concat(acc).concat(key, " {\n").concat(fields.join("\n"), "\n}\n");
            }
            if (element instanceof shared_1.z.ZodIntersection) {
                var fields = getUniqueFieldsFromIntersection(element);
                return "".concat(acc).concat(key, " {\n").concat(fields.join("\n"), "\n}\n");
            }
        }
        if (internal instanceof shared_1.z.ZodObject) {
            return "".concat(acc).concat(key, " {\n").concat(getFieldsFromSchema(internal), "}\n");
        }
        if (internal instanceof shared_1.z.ZodUnion) {
            var fields = getUniqueFieldsFromUnion(internal);
            return "".concat(acc).concat(key, " {\n").concat(fields.join("\n"), "\n}\n");
        }
        if (internal instanceof shared_1.z.ZodIntersection) {
            var fields = getUniqueFieldsFromIntersection(internal);
            return "".concat(acc).concat(key, " {\n").concat(fields.join("\n"), "\n}\n");
        }
        return "".concat(acc).concat(key, "\n");
    }, "");
}
exports.getFieldsFromSchema = getFieldsFromSchema;
