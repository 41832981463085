import React, { ReactNode } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import {
  Breadcrumbs,
  TBody,
  TR,
  TH,
  TD,
  Table,
  gutters,
  Button,
  useBoolean,
  Spinner,
} from "client-lib";
import { Layout } from "src/features/layout";
import { UpdateOrderLineItemModal } from "./order-form";
import {
  OrderLineItemData,
  OrderLineItemQuery,
  OrderLineItemVars,
} from "./queries";
import { ParseError, cutId } from "src/lib";
import { DAYTOSECONDS } from "src";

const Controls = styled.div`
  margin-bottom: ${gutters.xl}px;
  padding-top: ${gutters.md}px;
  display: flex;
  flex-direction: row;
  gap: ${gutters.sm}px;
`;

function Wrapper({
  children,
  id,
  orderId,
}: {
  children?: ReactNode;
  id: string;
  orderId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: `Orders/${cutId(orderId)}`, url: `/orders/${orderId}` },
          { title: id },
        ]}
      />
      <h1>Order line item {id}</h1>
      {children}
    </Layout>
  );
}

export function OrderLineItemPage() {
  const { params } = useRouteMatch<{ orderId: string; itemId: string }>();
  const { data, loading, error, refetch } = useQuery<
    OrderLineItemData,
    OrderLineItemVars
  >(OrderLineItemQuery, {
    variables: {
      id: params.itemId,
    },
  });

  const [
    isUpdateOrderLineItemOpen,
    { set: openUpdateOrderLineItem, reset: closeUpdateOrderLineItem },
  ] = useBoolean(false);

  if (loading) {
    return (
      <Wrapper id={params.itemId} orderId={params.orderId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper id={params.itemId} orderId={params.orderId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper id={params.itemId} orderId={params.orderId} />;
  }
  const item = data.OrderLineItem;

  return (
    <Wrapper id={params.itemId} orderId={params.orderId}>
      <Controls>
        <Button color="secondary" onClick={openUpdateOrderLineItem}>
          Edit Order line item
        </Button>
      </Controls>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>ID</TH>
            <TD>{item.id}</TD>
          </TR>
          <TR>
            <TH>Item name</TH>
            <TD>{parseLineItemType(item.type)}</TD>
          </TR>
          <TR>
            <TH>Created at</TH>
            <TD>{new Date(item.createdAt).toLocaleString()}</TD>
          </TR>
          <TR>
            <TH>Last updated</TH>
            <TD>{new Date(item.updatedAt).toLocaleString()}</TD>
          </TR>
          <TR>
            <TH>Granted period (days)</TH>
            <TD>
              {item.periodSeconds
                ? `${Math.floor(item.periodSeconds / DAYTOSECONDS)} days`
                : "Order line Item has no period"}
            </TD>
          </TR>
          <TR>
            <TH>Expires</TH>
            <TD>
              {item.periodSeconds
                ? new Date(
                    new Date(item.createdAt).getTime() +
                      item.periodSeconds * 1000
                  ).toLocaleString()
                : "Order line Item has no period"}
            </TD>
          </TR>
          <TR>
            <TH>Order ID</TH>
            <TD>{item.orderId}</TD>
          </TR>
          {item.userId ? (
            <TR>
              <TH>User ID</TH>
              <TD>{item.userId}</TD>
            </TR>
          ) : (
            <TR>
              <TH>Club ID</TH>
              <TD>{item.clubId}</TD>
            </TR>
          )}
        </TBody>
      </Table>

      {isUpdateOrderLineItemOpen && (
        <UpdateOrderLineItemModal
          orderLineItem={item}
          onCloseRequest={closeUpdateOrderLineItem}
          onUpdated={refetch}
        />
      )}
    </Wrapper>
  );
}

function parseLineItemType(type: string) {
  return (
    type.substring(0, 1) + type.substring(1).toLowerCase().replace("_", " ")
  );
}
