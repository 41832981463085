"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clubPostContentCategoryInputSchema = exports.clubPostContentCategorySchema = void 0;
const zod_1 = require("zod");
const content_category_1 = require("./content-category");
exports.clubPostContentCategorySchema = zod_1.z.object({
    clubPostId: zod_1.z.string().uuid(),
    contentCategoryId: zod_1.z.string().uuid(),
});
exports.clubPostContentCategoryInputSchema = exports.clubPostContentCategorySchema.merge(content_category_1.contentCategorySchema.pick({
    clubId: true,
    text: true,
}));
