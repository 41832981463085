import gql from "graphql-tag";
import {
  categorySchema,
  categoryWithIconSchema,
  categoryEnvironmentSchema,
  CategoryWithIcon,
  CategoryEnvironment,
  Category,
  CategoryInput,
  CategoryPatch,
} from "shared";
import { getFieldsFromSchema } from "client-lib";

export const CATEGORY_FULL_FRAGMENT = gql`
  fragment CategoryFullFragment on Category {
    ${getFieldsFromSchema(categorySchema)}
  }
`;

export const CATEGORY_WITH_ICON_FULL_FRAGMENT = gql`
  fragment CategoryWithIconFullFragment on CategoryWithIcon {
    ${getFieldsFromSchema(categoryWithIconSchema)}
  }
`;

export const CATEGORY_ENVIRONMENT_FULL_FRAGMENT = gql`
  fragment CategoryEnvironmentFullFragment on CategoryEnvironment {
    ${getFieldsFromSchema(categoryEnvironmentSchema)}
  }
`;

export const Categories = gql`
  query Categories($parentId: String) {
    Categories(parentId: $parentId) {
      ...CategoryWithIconFullFragment
    }
  }
  ${CATEGORY_WITH_ICON_FULL_FRAGMENT}
`;

export interface CategoriesVars {
  parentId?: string | null;
}

export type CategoriesData = {
  Categories: CategoryWithIcon[];
};

export const CategoryEnvironmentQuery = gql`
  query CategoryEnvironment($categoryId: String!) {
    CategoryEnvironment(categoryId: $categoryId) {
      ...CategoryEnvironmentFullFragment
    }
  }
  ${CATEGORY_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface CategoryEnvironmentVars {
  categoryId: string;
}

export type CategoryEnvironmentData = {
  CategoryEnvironment: CategoryEnvironment;
};

export const CreateCategory = gql`
  mutation CreateCategory($input: CategoryInput!) {
    CreateCategory(input: $input) {
      ...CategoryFullFragment
    }
  }
  ${CATEGORY_FULL_FRAGMENT}
`;

export interface CreateCategoryVars {
  input: CategoryInput;
}

export type CreateCategoryData = {
  CreateCategory: Category;
};

export const UpdateCategory = gql`
  mutation UpdateCategory($id: String!, $patch: CategoryPatch!) {
    UpdateCategory(id: $id, patch: $patch) {
      ...CategoryFullFragment
    }
  }
  ${CATEGORY_FULL_FRAGMENT}
`;

export interface UpdateCategoryVars {
  id: string;
  patch: CategoryPatch;
}

export type UpdateCategoryData = {
  CreateCategory: Category;
};
