import gql from "graphql-tag";
import {
  locationSchema,
  locationEnvironmentSchema,
  LocationEnvironment,
  Location,
} from "shared";
import { getFieldsFromSchema } from "client-lib";

export const LOCATION_FULL_FRAGMENT = gql`
  fragment LocationFullFragment on Location {
    ${getFieldsFromSchema(locationSchema)}
  }
`;

export const LOCATION_ENVIRONMENT_FULL_FRAGMENT = gql`
  fragment LocationEnvironmentFullFragment on LocationEnvironment {
    ${getFieldsFromSchema(locationEnvironmentSchema)}
  }
`;

export const Locations = gql`
  query Locations($parentId: String) {
    Locations(parentId: $parentId) {
      ...LocationFullFragment
    }
  }
  ${LOCATION_FULL_FRAGMENT}
`;

export interface LocationsVars {
  parentId?: string | null;
}

export type LocationsData = {
  Locations: Location[];
};

export const LocationEnvironmentQuery = gql`
  query LocationEnvironment($locationId: String!) {
    LocationEnvironment(locationId: $locationId) {
      ...LocationEnvironmentFullFragment
    }
  }
  ${LOCATION_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface LocationEnvironmentVars {
  locationId: string;
}

export type LocationEnvironmentData = {
  LocationEnvironment: LocationEnvironment;
};
