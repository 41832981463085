"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tierSchema = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
exports.tierSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    clubId: zod_1.z.string().uuid(),
    title: zod_1.z.string(),
    description: zod_1.z.string().nullable(),
    yearlyPriceRp: zod_1.z.number().int().positive(),
});
