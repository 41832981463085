import React from "react";
import { ClubEnvironment } from "shared";
import { HeaderCell, HeaderRow, List, ListLinkCell, ListRow } from "src/lib";

const columnTemplate = "0.5fr 0.5fr 0.5fr 0.5fr 0.5fr";

const defaultURL = (id: string) => `/clubs/${id}`;

interface Props {
  clubs: ClubEnvironment[];
  url?: (id: string) => string;
}

export function ClubList({ clubs, url: urlProp = defaultURL }: Props) {
  return (
    <List>
      <HeaderRow columnTemplate={columnTemplate}>
        <HeaderCell>ID</HeaderCell>
        <HeaderCell>Name</HeaderCell>
        <HeaderCell>Abbreviation</HeaderCell>
        <HeaderCell>Description</HeaderCell>
        <HeaderCell>Location</HeaderCell>
      </HeaderRow>
      {clubs.map(({ club }) => {
        const url = urlProp(club.id);
        return (
          <ListRow key={club.id} columnTemplate={columnTemplate}>
            <ListLinkCell to={url}>{club.id}</ListLinkCell>
            <ListLinkCell to={url}>{club.name}</ListLinkCell>
            <ListLinkCell to={url}>{club.abbreviation}</ListLinkCell>
            <ListLinkCell to={url}>{club.description}</ListLinkCell>
            <ListLinkCell to={url}>{club.locationId}</ListLinkCell>
          </ListRow>
        );
      })}
    </List>
  );
}
