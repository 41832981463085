"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tokenSchemaWithMeta = exports.tokenSchema = void 0;
const zod_1 = require("zod");
const user_1 = require("./user");
const auth_channel_1 = require("./auth-channel");
exports.tokenSchema = zod_1.z.object({
    user: user_1.tokenUserSchema,
    authChannel: auth_channel_1.tokenAuthChannelSchema,
    tokenSource: zod_1.z.string(),
});
exports.tokenSchemaWithMeta = exports.tokenSchema.extend({
    iat: zod_1.z.number().int().positive(),
    exp: zod_1.z.number().int().positive(),
});
