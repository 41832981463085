"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.lighten = exports.mute = exports.tint = exports.withAlpha = exports.errorRedMuted = exports.errorRed = exports.secondaryMuted = exports.secondary = exports.logo = exports.primaryMuted = exports.primary = exports.hoverGrey = exports.disabledGrey = exports.borderGrey = exports.mutedGrey = exports.black10 = exports.white10 = exports.grey95 = exports.grey90 = exports.grey80 = exports.grey70 = exports.grey60 = exports.grey50 = exports.grey40 = exports.grey30 = exports.grey20 = exports.grey15 = exports.grey10 = exports.grey5 = exports.trueBlack = exports.black = exports.white = void 0;
var lodash_1 = require("lodash");
exports.white = "#ffffff";
exports.black = "#08080d";
exports.trueBlack = "#000000";
exports.grey5 = "#fafafa";
exports.grey10 = "#f6f6f6";
exports.grey15 = "#f2f2f2";
exports.grey20 = "#e5e5e5";
exports.grey30 = "#d0d0d0";
exports.grey40 = "#b9b9ba";
exports.grey50 = "#a3a3a5";
exports.grey60 = "#8c8c8e";
exports.grey70 = "#767678";
exports.grey80 = "#616164";
exports.grey90 = "#343438";
exports.grey95 = "#1e1e23";
exports.white10 = withAlpha(exports.white, 0.1);
exports.black10 = withAlpha(exports.trueBlack, 0.1);
exports.mutedGrey = exports.grey40;
exports.borderGrey = exports.grey30;
exports.disabledGrey = exports.grey60;
exports.hoverGrey = exports.grey15;
exports.primary = "#006BCF";
exports.primaryMuted = mute(exports.primary, 0.5);
exports.logo = "#03fbfe";
exports.secondary = "#d81ca2"; // https://mycolor.space/?hex=%23D81CA2&sub=1
exports.secondaryMuted = mute(exports.secondary, 0.8);
exports.errorRed = "#ff2822";
exports.errorRedMuted = mute(exports.errorRed, 0.5);
/**
 * Convert hex color code and transparency to rgba
 *
 * @example withAlpha('#FF0000', 0.4); // '#FF000066'
 *
 * @param color {string} Hex color string #RRGGBB format
 * @param alpha {number} Number between 0 and 1 representing transparency
 * @return {string} rgba function formatted string
 */
function withAlpha(color, alpha) {
    if (alpha === void 0) { alpha = 1; }
    var _a = hexToDecimals(color), r = _a.r, g = _a.g, b = _a.b;
    return "rgba(".concat(r, ",").concat(g, ",").concat(b, ",").concat(alpha, ")");
}
exports.withAlpha = withAlpha;
function tint(color, percentage) {
    if (percentage === void 0) { percentage = 1; }
    var tintPart = function (part) {
        return Math.floor(part + (1 - percentage) * (255 - part));
    };
    var colors = hexToDecimals(color);
    var _a = (0, lodash_1.mapValues)(colors, tintPart), r = _a.r, g = _a.g, b = _a.b;
    return "rgb(".concat(r, ",").concat(g, ",").concat(b, ")");
}
exports.tint = tint;
function mute(color, factor) {
    if (factor === void 0) { factor = 0.8; }
    var hsv = rgbToHsv(hexToDecimals(color));
    var s = hsv.s * factor;
    var _a = hsvToRgb(__assign(__assign({}, hsv), { s: Math.min(1, s) })), r = _a.r, g = _a.g, b = _a.b;
    return "rgb(".concat(r, ",").concat(g, ",").concat(b, ")");
}
exports.mute = mute;
function lighten(color, factor) {
    if (factor === void 0) { factor = 1; }
    var hsv = rgbToHsv(hexToDecimals(color));
    var v = hsv.v * factor;
    var _a = hsvToRgb(__assign(__assign({}, hsv), { v: Math.min(1, v) })), r = _a.r, g = _a.g, b = _a.b;
    return "rgb(".concat(r, ",").concat(g, ",").concat(b, ")");
}
exports.lighten = lighten;
function colorToDecimal(colorSection) {
    return Number("0x".concat(colorSection));
}
function hexToDecimals(color) {
    var isHexColor = /^#([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})$/;
    var matches = color.toUpperCase().match(isHexColor);
    if (!matches) {
        throw new TypeError("You must pass a full six-digit hex code string: #RRGGBB");
    }
    return {
        b: colorToDecimal(matches[3]),
        g: colorToDecimal(matches[2]),
        r: colorToDecimal(matches[1]),
    };
}
function rgbToHsv(rgb) {
    var r = rgb.r, g = rgb.g, b = rgb.b;
    var max = Math.max(r, g, b);
    var min = Math.min(r, g, b);
    var d = max - min;
    var h;
    var s = max === 0 ? 0 : d / max;
    var v = max / 255;
    switch (max) {
        case r:
            h = g - b + d * (g < b ? 6 : 0);
            h /= 6 * d;
            break;
        case g:
            h = b - r + d * 2;
            h /= 6 * d;
            break;
        case b:
            h = r - g + d * 4;
            h /= 6 * d;
            break;
        case min:
        default:
            h = 0;
            break;
    }
    return {
        h: Number.isNaN(h) ? 0 : h,
        s: s,
        v: v,
    };
}
function hsvToRgb(hsv) {
    var h = hsv.h, s = hsv.s, v = hsv.v;
    var i = Math.floor(h * 6);
    var f = h * 6 - i;
    var p = v * (1 - s);
    var q = v * (1 - f * s);
    var t = v * (1 - (1 - f) * s);
    var r;
    var g;
    var b;
    switch (i % 6) {
        case 0:
            r = v;
            g = t;
            b = p;
            break;
        case 1:
            r = q;
            g = v;
            b = p;
            break;
        case 2:
            r = p;
            g = v;
            b = t;
            break;
        case 3:
            r = p;
            g = q;
            b = v;
            break;
        case 4:
            r = t;
            g = p;
            b = v;
            break;
        case 5:
        default:
            r = v;
            g = p;
            b = q;
            break;
    }
    return {
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255),
    };
}
