import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { v4 } from "uuid";
import { IconInput, Icon } from "shared";
import { ParseError } from "src/lib";
import { IconForm } from "./icon-form";
import { CreateIcon, CreateIconVars, CreateIconData } from "../queries";

interface Props {
  onCompleted: (icon: Icon) => any;
}

export function NewIcon({ onCompleted }: Props) {
  const [create, { loading, error }] = useMutation<
    CreateIconData,
    CreateIconVars
  >(CreateIcon);
  const handleSubmit = useCallback(
    async (input: IconInput) => {
      const { data } = await create({ variables: { input } });
      onCompleted(data!.CreateIcon);
    },
    [create, onCompleted]
  );

  return (
    <React.Fragment>
      {error ? <ParseError error={error} /> : null}
      <IconForm
        initialValues={{
          id: v4(),
          svg: "",
          meta: null,
        }}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle="Create"
      />
    </React.Fragment>
  );
}
