"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.standardAttributesSchema = exports.standardAttributes = exports.checkSchema = void 0;
const zod_1 = require("zod");
function checkSchema(_schema) { }
exports.checkSchema = checkSchema;
exports.standardAttributes = {
    id: zod_1.z.string().uuid(),
    createdAt: zod_1.z.date(),
    updatedAt: zod_1.z.date(),
    deletedAt: zod_1.z.date().nullable(),
};
exports.standardAttributesSchema = zod_1.z.object(exports.standardAttributes);
