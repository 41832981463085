"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWNER_PERMISSIONS = exports.CLUB_BOARD_PERMISSIONS = exports.CLUB_MEMBER_PERMISSIONS = exports.CLUB_FOLLOWER_PERMISSIONS = exports.CLUB_NO_PERMISSIONS = exports.CLUB_FULL_PERMISSIONS = exports.clubPermissionSchema = exports.ClubPermission = void 0;
const zod_1 = require("zod");
var ClubPermission;
(function (ClubPermission) {
    ClubPermission["JOIN_EVENT"] = "JOIN_EVENT";
    ClubPermission["EDIT_EVENT"] = "EDIT_EVENT";
    ClubPermission["EDIT_CLUB"] = "EDIT_CLUB";
    ClubPermission["MANAGE_MEMBERS"] = "MANAGE_MEMBERS";
    ClubPermission["MANAGE_BOARD"] = "MANAGE_BOARD";
})(ClubPermission = exports.ClubPermission || (exports.ClubPermission = {}));
exports.clubPermissionSchema = zod_1.z.nativeEnum(ClubPermission);
exports.CLUB_FULL_PERMISSIONS = new Set(Object.values(ClubPermission));
exports.CLUB_NO_PERMISSIONS = new Set();
exports.CLUB_FOLLOWER_PERMISSIONS = new Set();
exports.CLUB_MEMBER_PERMISSIONS = new Set([ClubPermission.JOIN_EVENT]);
exports.CLUB_BOARD_PERMISSIONS = new Set([
    ...exports.CLUB_MEMBER_PERMISSIONS,
    ClubPermission.EDIT_EVENT,
    ClubPermission.EDIT_CLUB,
    ClubPermission.MANAGE_MEMBERS,
]);
exports.OWNER_PERMISSIONS = new Set([
    ...exports.CLUB_BOARD_PERMISSIONS,
    ClubPermission.MANAGE_BOARD,
]);
