"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contentCategoryInputSchema = exports.contentCategorySchema = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
exports.contentCategorySchema = zod_1.z.object({
    ...services_1.standardAttributes,
    clubId: zod_1.z.string().uuid(),
    text: zod_1.z.string(),
});
(0, services_1.checkSchema)(exports.contentCategorySchema);
exports.contentCategoryInputSchema = exports.contentCategorySchema.pick({
    id: true,
    clubId: true,
    text: true,
});
