import gql from "graphql-tag";
import { iconSchema, Icon, IconInput, IconPatch } from "shared";
import { getFieldsFromSchema } from "client-lib";

export const ICON_FULL_FRAGMENT = gql`
  fragment IconFullFragment on Icon {
    ${getFieldsFromSchema(iconSchema)}
  }
`;

export const Icons = gql`
  query Icons {
    Icons {
      ...IconFullFragment
    }
  }
  ${ICON_FULL_FRAGMENT}
`;

export interface IconsVars {}

export type IconsData = {
  Icons: Icon[];
};

export const CreateIcon = gql`
  mutation CreateIcon($input: IconInput!) {
    CreateIcon(input: $input) {
      ...IconFullFragment
    }
  }
  ${ICON_FULL_FRAGMENT}
`;

export interface CreateIconVars {
  input: IconInput;
}

export type CreateIconData = {
  CreateIcon: Icon;
};

export const UpdateIcon = gql`
  mutation UpdateIcon($patch: IconPatch!, $id: String!) {
    UpdateIcon(patch: $patch, id: $id) {
      ...IconFullFragment
    }
  }
  ${ICON_FULL_FRAGMENT}
`;

export interface UpdateIconVars {
  id: string;
  patch: IconPatch;
}

export type UpdateIconData = {
  UpdateIcon: Icon;
};
