import React, { useCallback, useMemo } from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Icon } from "shared";
import { grey20, Modal, useBoolean } from "client-lib";
import { useField } from "formik";
import { Icons, IconsData, IconsVars } from "./queries";
import { IconItem } from "./icon-item";

const Container = styled.div``;

const Value = styled.div`
  cursor: pointer;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ItemWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  :hover {
    background: ${grey20};
  }
`;

interface Props {
  name: string;
}

export function IconField({ name }: Props) {
  const [isOpen, { set: open, reset: close }] = useBoolean();
  const [{ value }, , { setValue }] = useField<string | null>(name);
  const { data } = useQuery<IconsData, IconsVars>(Icons);

  const valueIcon = useMemo<Icon | null>(() => {
    if (!value || !data) {
      return null;
    }
    return data.Icons.find((i) => i.id === value) || null;
  }, [data, value]);

  const handleClick = useCallback(
    (icon: Icon) => {
      setValue(icon.id);
      close();
    },
    [setValue, close]
  );

  return (
    <Container>
      <Value onClick={open}>
        {!value && "No icon selected"}
        {value && !valueIcon && `Icon #${value}`}
        {value && valueIcon && <IconItem svg={valueIcon.svg} />}
      </Value>
      {isOpen && (
        <Modal onCloseRequest={close} title="Pick icon">
          <IconsContainer>
            {data?.Icons.map((icon) => (
              <ItemWrapper key={icon.id} onClick={() => handleClick(icon)}>
                <IconItem svg={icon.svg} />
              </ItemWrapper>
            ))}
          </IconsContainer>
        </Modal>
      )}
    </Container>
  );
}
