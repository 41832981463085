import gql from "graphql-tag";
import { fileSchema, File } from "shared";
import { getFieldsFromSchema } from "client-lib";

export const FILE_FULL_FRAGMENT = gql`
  fragment FileFullFragment on File {
    ${getFieldsFromSchema(fileSchema)}
  }
`;

export const Files = gql`
  query Files {
    Files {
      ...FileFullFragment
    }
  }
  ${FILE_FULL_FRAGMENT}
`;

export interface FilesVars {}

export type FilesData = {
  Files: File[];
};

export const FileQuery = gql`
  query File($fileId: String!) {
    File(fileId: $fileId) {
      ...FileFullFragment
    }
  }
  ${FILE_FULL_FRAGMENT}
`;

export interface FileVars {
  fileId: string;
}

export type FileData = {
  File: File;
};
