"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colorFromUuid = void 0;
var MIN_HUE = 0;
var MAX_HUE = 360;
// Valid numbers are 0-100, but 30-80 prevents dull grays and super bright colors
var MIN_SATURATION = 30;
var MAX_SATURATION = 80;
// Compress a value in the range [0 - initialMaximum] to a number in the range
// [newMinumum - newMaximum]
function castToRange(value, initialMaximum, newMinimum, newMaximum) {
    var range = newMaximum - newMinimum;
    return newMinimum + value / (initialMaximum / range);
}
var colorFromUuid = function (uuid) {
    // First we filter down the UUID to only match digits (easier than parsing hex)
    var digits = uuid.match(/\d/g) || [];
    // Take the first 3 to represent an evenly-distributed number in the range
    // 0-999 for the purposes of seeding the hue, then compress it into the range
    // 0-360 representing the range of all possible hues
    var hueSeed = parseInt(digits.slice(0, 3).join(""), 10);
    var hue = castToRange(hueSeed, 1000, MIN_HUE, MAX_HUE);
    // Then the next 2 (0-100) for saturation
    var saturationSeed = parseInt(digits.slice(3, 5).join(""), 10);
    var saturation = castToRange(saturationSeed, 100, MIN_SATURATION, MAX_SATURATION);
    return "hsl(".concat(hue, ", ").concat(saturation, "%, 75%)");
};
exports.colorFromUuid = colorFromUuid;
