import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { v4 } from "uuid";
import { CategoryInput, Category } from "shared";
import { ParseError } from "src/lib";
import { CategoryForm } from "./category-form";
import {
  CreateCategory,
  CreateCategoryVars,
  CreateCategoryData,
} from "../queries";

interface Props {
  onCompleted: (category: Category) => any;
}

export function NewCategory({ onCompleted }: Props) {
  const [create, { loading, error }] = useMutation<
    CreateCategoryData,
    CreateCategoryVars
  >(CreateCategory);
  const handleSubmit = useCallback(
    async (input: CategoryInput) => {
      const { data } = await create({ variables: { input } });
      onCompleted(data!.CreateCategory);
    },
    [create, onCompleted]
  );

  return (
    <React.Fragment>
      {error ? <ParseError error={error} /> : null}
      <CategoryForm
        initialValues={{
          id: v4(),
          name: "",
          parentId: null,
          iconId: null,
        }}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle="Create"
      />
    </React.Fragment>
  );
}
