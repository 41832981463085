import React from "react";
import { isEmpty, capitalize } from "lodash";
import styled from "@emotion/styled";
import { OrderFinancialStatus, OrderPatch, OrderStatus } from "shared";
import { Button, gutters, grey30, SelectField } from "client-lib";
import { Form, Formik, FormikProps } from "formik";
import { orderPatchFormikSchema } from "./types";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

const Error = styled.div`
  margin-top: ${gutters.sm}px;
  padding-top: ${gutters.sm}px;
  color: red;
`;

interface Props {
  initialValues: OrderPatch;
  disabled: boolean;
  onSubmit: (values: OrderPatch) => any;
}

export function OrderForm({ initialValues, disabled, onSubmit }: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={orderPatchFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty, errors }: FormikProps<OrderPatch>) => (
        <Form>
          <SelectField name="status" label="Order Status">
            <option value={initialValues.status}>
              {capitalize(initialValues.status)}
            </option>
            {Object.values(OrderStatus).map((data, idx) =>
              data === initialValues.status ? null : (
                <option key={idx} value={data}>
                  {capitalize(data)}
                </option>
              )
            )}
          </SelectField>
          <br />
          <SelectField name="financialStatus" label="Financial status">
            <option value={initialValues.financialStatus}>
              {capitalize(initialValues.financialStatus)}
            </option>
            {Object.values(OrderFinancialStatus).map((data, idx) =>
              data === initialValues.financialStatus ? null : (
                <option key={idx} value={data}>
                  {capitalize(data)}
                </option>
              )
            )}
          </SelectField>
          <Error>{errors && !isEmpty(errors) && JSON.stringify(errors)}</Error>
          <Controls>
            <Button type="submit" disabled={disabled || !isValid || !dirty}>
              Save
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
