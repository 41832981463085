import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Spinner } from "client-lib";
import {
  HeaderCell,
  HeaderRow,
  List,
  ListLinkCell,
  ListRow,
  ParseError,
} from "src/lib";
import { Layout } from "src/features/layout";
import { Files, FilesData, FilesVars } from "./queries";
import { FileUploader } from "./file-uploader";

const columnTemplate = "1fr 1fr 0.5fr 0.5fr 1fr";

const Container = styled.div`
  __display: flex;
  flex-wrap: wrap;
`;

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <h1>Files</h1>
      {children}
    </Layout>
  );
}

export function FilesPage() {
  const { data, loading, error, refetch } = useQuery<FilesData, FilesVars>(
    Files
  );

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <Container>
        <List>
          <HeaderRow columnTemplate={columnTemplate}>
            <HeaderCell>ID</HeaderCell>
            <HeaderCell>User</HeaderCell>
            <HeaderCell>Size</HeaderCell>
            <HeaderCell>MIME</HeaderCell>
            <HeaderCell>File Name</HeaderCell>
          </HeaderRow>
          {data.Files.map((file) => {
            const url = `/files/${file.id}`;
            return (
              <ListRow key={file.id} columnTemplate={columnTemplate}>
                <ListLinkCell to={url}>{file.id}</ListLinkCell>
                <ListLinkCell to={url}>{file.userId}</ListLinkCell>
                <ListLinkCell to={url}>{file.size}</ListLinkCell>
                <ListLinkCell to={url}>{file.mimeType}</ListLinkCell>
                <ListLinkCell to={url}>{file.originalFileName}</ListLinkCell>
              </ListRow>
            );
          })}
        </List>
      </Container>
      <h1>Upload new file</h1>
      <FileUploader onCompleted={refetch} />
    </Wrapper>
  );
}
