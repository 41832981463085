import React from "react";
import { useQuery } from "@apollo/client";
import { SelectField, WithFieldProps } from "client-lib";
import { Categories, CategoriesData, CategoriesVars } from "./queries";

type Props = Omit<WithFieldProps, "children">;

export function CategoryField(props: Props) {
  const { data } = useQuery<CategoriesData, CategoriesVars>(Categories, {
    variables: {
      parentId: "*",
    },
  });

  return (
    <SelectField {...props}>
      <option value="">Not selected</option>
      {data?.Categories.map(({ category }) => (
        <option key={category.id} value={category.id}>
          {category.name}
        </option>
      ))}
    </SelectField>
  );
}
