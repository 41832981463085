import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { Spinner } from "client-lib";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import { Categories, CategoriesData, CategoriesVars } from "./queries";
import { CategoryList } from "./category-list";
import { NewCategory } from "./category-form";

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <h1>Categories</h1>
      {children}
    </Layout>
  );
}

export function CategoriesPage() {
  const { data, loading, error, refetch } = useQuery<
    CategoriesData,
    CategoriesVars
  >(Categories, {
    variables: {
      parentId: null,
    },
  });

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <CategoryList categories={data.Categories} />
      <h1>Create new category</h1>
      <NewCategory onCompleted={refetch} />
    </Wrapper>
  );
}
