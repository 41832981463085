import React, { useCallback, useState, useRef } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import { useAuthData } from "src/features/auth";
import { Button, errorRed } from "client-lib";
import { File as ZFile } from "shared";

const Container = styled.label`
  display: flex;
  align-items: center;
`;

const ErrorBlock = styled.label`
  margin-left: 1em;
  color: ${errorRed};
`;

type Status = "idle" | "uploading" | "error";

interface Props {
  onCompleted: (file: ZFile) => any;
}

export function FileUploader({ onCompleted }: Props) {
  const { token } = useAuthData();
  const fileRef = useRef<File | null>(null);
  const [status, setStatus] = useState<Status>("idle");

  const handleUpload = useCallback(() => {
    if (!fileRef.current) {
      return;
    }
    const url = `${process.env.REACT_APP_REST_URL}/file`;
    const formData = new FormData();
    formData.append("file", fileRef.current);
    const config = {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "Content-Type": "multipart/form-data",
      },
    };
    setStatus("uploading");

    axios
      .post(url, formData, config)
      .then((response) => {
        fileRef.current = null;
        if (response.status !== 200) {
          throw new Error("Response status is error");
        }
        setStatus("error");
        onCompleted(response.data);
      })
      .catch(() => {
        setStatus("error");
      });
  }, [onCompleted, token]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files?.length) {
        return;
      }
      const file = event.target.files[0];

      fileRef.current = file;
      handleUpload();
    },
    [handleUpload]
  );

  return (
    <Container>
      {status === "idle" && (
        <React.Fragment>
          <input type="file" hidden onChange={handleChange} />
          <Button tag="div">Select file</Button>
        </React.Fragment>
      )}
      {status === "uploading" && <Button disabled>Uploading&hellip;</Button>}
      {status === "error" && (
        <React.Fragment>
          <input type="file" hidden onChange={handleChange} />
          <Button tag="div">Try again</Button>
          <ErrorBlock>An error occured</ErrorBlock>
        </React.Fragment>
      )}
    </Container>
  );
}
