import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch, Link } from "react-router-dom";
import { Category } from "shared";
import {
  Breadcrumbs,
  Spinner,
  TBody,
  TR,
  TH,
  TD,
  Table,
  Button,
  useBoolean,
} from "client-lib";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import {
  CategoryEnvironmentQuery,
  CategoryEnvironmentData,
  CategoryEnvironmentVars,
} from "./queries";
import { IconItem } from "../icon";
import { CategoryList } from "./category-list";
import { UpdateCategoryModal } from "./category-form";

function Wrapper({
  children,
  category,
  categoryId,
}: {
  children?: ReactNode;
  category?: Category;
  categoryId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Categories", url: "/categories" },
          { title: category ? category.name : `${categoryId}` },
        ]}
      />
      <h1>
        Category {category ? `\u00ab${category.name}\u00bb` : `${categoryId}`}
      </h1>
      {children}
    </Layout>
  );
}

export function CategoryPage() {
  const [
    isUpdateCategoryOpen,
    { set: openUpdateCategory, reset: closeUpdateCategory },
  ] = useBoolean(false);

  const { params } = useRouteMatch<{ categoryId: string }>();
  const { data, loading, error, refetch } = useQuery<
    CategoryEnvironmentData,
    CategoryEnvironmentVars
  >(CategoryEnvironmentQuery, {
    variables: {
      categoryId: params.categoryId,
    },
  });

  if (loading) {
    return (
      <Wrapper categoryId={params.categoryId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper categoryId={params.categoryId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper categoryId={params.categoryId} />;
  }

  const {
    categoryWithIcon: { category, icon },
    parent,
    children,
  } = data.CategoryEnvironment;
  return (
    <Wrapper categoryId={params.categoryId} category={category}>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>ID</TH>
            <TD>{category.id}</TD>
          </TR>
          <TR>
            <TH>Original Zirkl id</TH>
            <TD>{category.originalZirklId}</TD>
          </TR>
          <TR>
            <TH>Name</TH>
            <TD>{category.name}</TD>
          </TR>
          <TR>
            <TH>Icon</TH>
            <TD>{icon ? <IconItem svg={icon.svg} /> : "None"}</TD>
          </TR>
          <TR>
            <TH>Parent category</TH>
            <TD>
              {parent ? (
                <Link to={`/categories/${parent.category.id}`}>
                  {parent.category.name}
                </Link>
              ) : (
                "None"
              )}
            </TD>
          </TR>
          <TR>
            <TH>Clubs</TH>
            <TD>{category.clubCount}</TD>
          </TR>
          <TR>
            <TH>Children</TH>
            <TD>{category.childrenCount}</TD>
          </TR>
        </TBody>
      </Table>
      <br />
      <Button onClick={openUpdateCategory}>Edit category</Button>
      <br />
      <h1>Children</h1>
      <CategoryList categories={children} />
      {isUpdateCategoryOpen && (
        <UpdateCategoryModal
          category={category}
          onCloseRequest={closeUpdateCategory}
          onUpdated={refetch}
        />
      )}
    </Wrapper>
  );
}
