import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { LoginBlock } from "./login-block";

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export function LoginPage() {
  const { search } = useLocation();
  const redirectTo = useMemo<string | null>(() => {
    const redirectValue = new URLSearchParams(search).get("redirect");
    return redirectValue ? decodeURIComponent(redirectValue) : null;
  }, [search]);

  return (
    <Container>
      <h1>Zirkl admin panel</h1>
      <LoginBlock redirectTo={redirectTo || "/"} />
    </Container>
  );
}
