import { z } from "shared";
import { toFormikSchema } from "client-lib";

export const notificationInput = z.object({
  title: z.string().min(1),
  body: z.string().min(1),
  link: z.string().nullable(),
});

export type NotificationInput = z.infer<typeof notificationInput>;

export const notificationInputFormikSchema = toFormikSchema(notificationInput);
