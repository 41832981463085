import React from "react";
import { isEmpty } from "lodash";
import styled from "@emotion/styled";
import { CategoryWithIcon, ClubInput, ClubPatch } from "shared";
import {
  Button,
  CheckboxField,
  MultiSelectField,
  MultilineTextField,
  TextField,
  gutters,
  grey30,
} from "client-lib";
import { Form, Formik, FormikProps } from "formik";
import { clubInputFormikSchema, clubPatchFormikSchema } from "./types";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

const Error = styled.div`
  margin-top: ${gutters.sm}px;
  padding-top: ${gutters.sm}px;
  color: red;
`;

interface Props {
  type: "create" | "edit";
  initialValues: ClubPatch | ClubInput;
  disabled: boolean;
  onSubmit: (values: ClubInput) => any;
  categories?: CategoryWithIcon[];
}

export function ClubForm({
  type,
  initialValues,
  disabled,
  categories,
  onSubmit,
}: Props) {
  // Needs to be discussed
  const values =
    type === "create"
      ? {
          ...initialValues,
          reachLocationIds: ["d86e76ad-91bb-4171-8826-4da9e10132ed"],
        }
      : initialValues;

  return (
    <Formik
      initialValues={values as ClubInput}
      validationSchema={
        type === "create" ? clubInputFormikSchema : clubPatchFormikSchema
      }
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty, errors }: FormikProps<ClubInput>) => (
        <Form>
          <TextField
            label="Name"
            controlProps={{ maxLength: 100 }}
            name="name"
          />
          <br />
          <TextField
            label="Abbreviation"
            controlProps={{ maxLength: 20 }}
            name="abbreviation"
          />
          <br />
          <TextField
            label="Color"
            controlProps={{ maxLength: 7 }}
            name="color"
          />
          <br />
          <TextField
            label="E-Mail"
            controlProps={{ maxLength: 20 }}
            name="email"
          />
          <br />
          <MultilineTextField
            label="Description"
            rows={10}
            name="description"
            controlProps={{ maxlength: 10000 }}
          />
          <MultilineTextField
            label="Rules"
            rows={10}
            name="rules"
            controlProps={{ maxlength: 10000 }}
          />
          <br />
          <TextField
            label="Location"
            controlProps={{ maxLength: 100 }}
            name="locationId"
          />
          <br />
          {type === "create" && (
            <MultiSelectField
              name="categoryIds"
              label="Categories"
              placeholder="Select Categories..."
              width={400}
              options={categories?.map((data) => ({
                value: data.category.id,
                label: data.category.name,
              }))}
            />
          )}{" "}
          <br />
          <CheckboxField name="isPublic" label="Public" />
          <br />
          <CheckboxField name="isActive" label="Active" />
          <br />
          <Error>{errors && !isEmpty(errors) && JSON.stringify(errors)}</Error>
          <Controls>
            <Button type="submit" disabled={disabled || !isValid || !dirty}>
              {type === "create" ? "Create" : "Save"}
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
