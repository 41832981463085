import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { Formik, Form } from "formik";
import { z } from "shared";
import {
  TextField,
  Button,
  toFormikSchema,
  gutters,
  grey10,
  grey20,
} from "client-lib";

const Container = styled.div`
  padding: ${gutters.xxl}px ${gutters.xxl}px ${gutters.xl}px;
  background: ${grey10};
  border: 1px solid ${grey20};
  width: 400px;
  input {
    width: 100%;
    margin-bottom: ${gutters.md}px;
  }
`;

const loginFormSchema = z.object({
  email: z.string().email("Invalid email"),
  password: z.string().min(4, "Password should contain at least 6 characters"),
});

export type LoginFormData = z.infer<typeof loginFormSchema>;
const formikSchema = toFormikSchema(loginFormSchema);

export function LoginForm({
  onSubmit,
  pending,
}: {
  onSubmit: (data: LoginFormData) => void;
  pending?: boolean;
}) {
  const handleSubmit = useCallback(
    (values: LoginFormData) => {
      if (pending) {
        return;
      }
      onSubmit(values);
    },
    [onSubmit, pending]
  );

  return (
    <Container>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={formikSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextField
            label="Email:"
            name="email"
            controlProps={{ size: 12 }}
            disabled={pending}
          />
          <TextField
            label="Password:"
            name="password"
            type="password"
            controlProps={{ size: 12 }}
            disabled={pending}
          />
          <Button type="submit">Log in</Button>
        </Form>
      </Formik>
    </Container>
  );
}
