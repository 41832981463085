"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.categoryPatchSchema = exports.categoryInputSchema = exports.categoryEnvironmentSchema = exports.categoryWithIconSchema = exports.categorySchema = void 0;
const zod_1 = require("zod");
const services_1 = require("../services");
const icon_1 = require("./icon");
exports.categorySchema = zod_1.z.object({
    ...services_1.standardAttributes,
    name: zod_1.z.string(),
    iconId: zod_1.z.string().uuid().nullable(),
    parentId: zod_1.z.string().uuid().nullable(),
    originalZirklId: zod_1.z.number().int().positive().nullable(),
    clubCount: zod_1.z.number().int().nonnegative(),
    childrenCount: zod_1.z.number().int().nonnegative(),
});
(0, services_1.checkSchema)(exports.categorySchema);
exports.categoryWithIconSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    category: exports.categorySchema,
    icon: icon_1.iconSchema.nullable(),
});
exports.categoryEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    categoryWithIcon: exports.categoryWithIconSchema,
    parent: exports.categoryWithIconSchema.nullable(),
    children: zod_1.z.array(exports.categoryWithIconSchema),
});
exports.categoryInputSchema = exports.categorySchema.pick({
    id: true,
    name: true,
    iconId: true,
    parentId: true,
});
exports.categoryPatchSchema = exports.categoryInputSchema.omit({
    id: true,
});
